import { Col, Form, Input, Row, Select, Button, notification, Switch } from 'antd';
import TabelaRamos from '../../componentes/TabelaRamos';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';

function ConsultaRamosView() {
  usePageTitle('Lista de Ramos');
  useBreadcrumb('Home/Ramos/Consulta');

  return <TabelaRamos></TabelaRamos>;
}

export default ConsultaRamosView;
