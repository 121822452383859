import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserService, Usuarios } from '../../sdk';
import { Lojas } from '../../sdk/@types/Restaurantes';
import LojaService from '../../sdk/services/Loja.service';

export default function useLojaUsuario() {
  const access_token = localStorage.getItem('accessToken');
  const params = useParams<{ id: string }>();
  const [usuarioLoja, setUsuarioLoja] = useState<Usuarios.UsuarioDetalhado>();

  const fetchUsuarioLoja = useCallback((userId: string) => {
    UserService.getUsuarioDetalhado(userId).then(tratarDadosUsuario).then(setUsuarioLoja);
  }, []);

  function tratarDadosUsuario(
    dados: Usuarios.UsuarioDetalhado
  ): Usuarios.UsuarioDetalhado {
    return dados;
  }

  return {
    usuarioLoja,
    fetchUsuarioLoja,
  };
}
