import { Card, Space, Table, Tag, Tooltip } from 'antd';
import { Col, Form, Input, Row, Select, Button, notification, Switch } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import useBreadcrumb from '../core/hooks/useBreadcrumb';
import { UserService } from '../sdk';
import { Usuarios } from '../sdk/@types/Usuarios';
import {
  EyeOutlined,
  EditOutlined,
  SearchOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { idText } from 'typescript';
import CozinhaService from '../sdk/services/Cozinha.service';
import { Cozinhas } from '../sdk/@types/Cozinha';

export default function TabelaRamos() {
  const [data, setData] = useState<any[]>();
  const [fetching, setFetching] = useState<boolean>(true);

  const [error, setError] = useState<Error>();

  useEffect(() => {
    setFetching(true);
    CozinhaService.getAll()
      .then(tratarDados)
      .then(setData)
      .catch((error) => {
        setError(new Error(error.message));
      });
  }, []);

  function tratarDados(dados: Cozinhas.Cozinha): Cozinhas.GradeRamos[] {
    if (dados._embedded == undefined) {
      setFetching(false);
      return [];
    } else {
      setFetching(false);
      return dados._embedded.cozinhas;
    }
  }

  const getColumnSearchProps = (
    dataIndex: keyof Cozinhas.GradeRamos,
    displayName?: string
  ): ColumnProps<Usuarios.GradeRestaurantes> => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
      <Card>
        <Input
          style={{ marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          //placeholder={`Buscar ${displayName || dataIndex}`}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => confirm()}
        />
        <Space>
          <Button
            type={'primary'}
            size={'small'}
            style={{ width: 90 }}
            onClick={() => confirm()}
            icon={<SearchOutlined />}
          >
            Buscar
          </Button>

          <Button onClick={clearFilters} size={'small'} style={{ width: 90 }}>
            Limpar
          </Button>
        </Space>
      </Card>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#0099ff' : undefined }} />
    ),
    // @ts-ignore
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : '',
  });

  const columns: any = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      ...getColumnSearchProps('nome', 'Nome'),
      key: 'nome',
    },
    {
      title: 'Ação',
      dataIndex: 'id',
      fixed: 'right',
      key: 'acao',
      render(id: number) {
        return (
          <>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Button size='small' icon={<EyeOutlined />} />
              <Tooltip title={'Editar Ramo'} placement={'right'}>
                <Link to={`/ramo/editar/${id}`}>
                  <Button size='small' icon={<EditOutlined />}></Button>
                </Link>
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <Form autoComplete={'off'} layout={'horizontal'} onFinish={async (user: any) => {}}>
      <Table
        loading={fetching}
        pagination={{ pageSize: 20 }}
        dataSource={data}
        columns={columns}
        scroll={{ x: '1190' }}
      />
    </Form>
  );
}
