import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserService, Usuarios } from '../../sdk';
import { Lojas } from '../../sdk/@types/Restaurantes';
import LojaService from '../../sdk/services/Loja.service';

export default function useEmissor() {
  const [emissor, setEmissor] = useState<Lojas.Emissor>();

  const fetchEmissor = useCallback((cnpj: string) => {
    LojaService.getEmissorByCnpj(cnpj).then(setEmissor);
  }, []);

  return {
    emissor,
    fetchEmissor,
  };
}
