import { Skeleton } from 'antd';
import { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import useLoja from '../../core/hooks/useLoja';
import FormularioDeLoja from '../features/FormularioDeLoja';

export default function EdicaoDeLojaView() {
  useBreadcrumb('Home/Lojas/EditarLoja');
  const parametroUrl = useParams<{ id: string }>();
  const { loja, fetchLoja } = useLoja();

  useEffect(() => {
    fetchLoja(parametroUrl.id);
  }, [fetchLoja]);

  if (!loja) return <Skeleton />;

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginTop: '-35px',
          fontWeight: 'bold',
        }}
      >
        ATENÇÃO: Utilizar Para Criar Usuário Proprietário da Loja (Que vai usar o Manager)
      </div>
      <div style={{ width: '100%', height: '15px' }}></div>
      <FormularioDeLoja loja={loja} />
    </>
  );
}
