// User.service.ts
import transformarDados from '../../app/funcoes/TransformarDados';
import { Cidades } from '../@types/Cidades';
import { Estados } from '../@types/Estados';
import { Lojas } from '../@types/Restaurantes';
import { Usuarios } from '../@types/Usuarios';
import Service from '../Service';

class EstadoService extends Service {
  static getAll() {
    return this.HttpTechFood.get<Estados.Estado>(`/v1/estados`).then(this.getData);
  }

  static getById(estadoId: string) {
    return this.HttpTechFood.get<Estados.GradeEstados>(`/v1/estados/${estadoId}`).then(
      this.getData
    );
  }

  static atualizarEstado(data: Estados.formEstados, estadoId: string) {
    return this.HttpTechFood.put<any>(`/v1/estados/${estadoId}`, data).then(this.getData);
  }

  static buscarPorUF(estadoUf: string) {
    return this.HttpTechFood.get<Estados.Estado>(`/v1/estados/buscarUf/${estadoUf}`).then(
      this.getData
    );
  }

  static cadastrarEstado(data: Estados.formEstados) {
    return this.HttpTechFood.post<any>(`/v1/estados`, data).then(this.getData);
  }
  static excluirEstadoByID(estadoId: string) {
    return this.HttpTechFood.delete<any>(`/v1/estados/${estadoId}`, {}).then(
      this.getData
    );
  }
}

export default EstadoService;
