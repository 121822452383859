import { Card, Space, Table, Tag } from 'antd';
import { Col, Form, Input, Row, Select, Button, notification, Switch } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useCallback, useEffect, useState } from 'react';
import useBreadcrumb from '../core/hooks/useBreadcrumb';
import { UserService } from '../sdk';
import { Usuarios } from '../sdk/@types/Usuarios';
import {
  EyeOutlined,
  EditOutlined,
  SearchOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import LojaService from '../sdk/services/Loja.service';
import { useParams } from 'react-router-dom';
import App from './Modal';
import ModalUsuario from './ModalUsuario';
import { Lojas } from '../sdk/@types/Restaurantes';

interface TabelaUsuariosPorLojaProps {
  user?: Usuarios.UsuarioDetalhado;
}
export default function TabelaUsuariosPorLoja(props: TabelaUsuariosPorLojaProps) {
  useBreadcrumb('Home/Lojas/Editar');
  const parametroUrl = useParams<{ id: string }>();
  const idLoja: string = parametroUrl.id ? parametroUrl.id : '0';
  const [data, setData] = useState<Usuarios.UsuarioTratado[]>();
  const [ativo, setAtivo] = useState<boolean>(true);
  const [fetching, setFetching] = useState<boolean>(true);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const openCreationModal = useCallback(() => setShowCreationModal(true), []);
  const closeCreationModal = useCallback(() => setShowCreationModal(false), []);

  const [reload, setReload] = useState<boolean>(true);

  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (idLoja !== '0') {
      LojaService.getResponsaveisDaLoja(idLoja)
        .then(tratarListaDeUsuarios)
        .then(setData)
        .catch((error) => {
          setError(new Error(error.message));
        });
      setFetching(true);
    } else {
      setData([]);
      setFetching(false);
    }
  }, [reload]);

  function tratarListaDeUsuarios(
    dados: Usuarios.ListaUsuarios
  ): Usuarios.UsuarioTratado[] {
    setFetching(false);
    return dados._embedded.usuarios;
  }

  const getColumnSearchProps = (
    dataIndex: keyof Usuarios.UsuarioTratado,
    displayName?: string
  ): ColumnProps<Usuarios.UsuarioTratado> => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
      <Card>
        <Input
          style={{ marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          //placeholder={`Buscar ${displayName || dataIndex}`}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => confirm()}
        />
        <Space>
          <Button
            type={'primary'}
            size={'small'}
            style={{ width: 90 }}
            onClick={() => confirm()}
            icon={<SearchOutlined />}
          >
            Buscar
          </Button>

          <Button onClick={clearFilters} size={'small'} style={{ width: 90 }}>
            Limpar
          </Button>
        </Space>
      </Card>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#0099ff' : undefined }} />
    ),
    // @ts-ignore
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : '',
  });

  const columns: any = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      ...getColumnSearchProps('nome', 'Nome'),
      key: 'nome',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ...getColumnSearchProps('email', 'Email'),
      key: 'email',
    },
    {
      title: 'Bloqueado',
      dataIndex: 'bloqueio',
      key: 'bloqueio ',
      render(bloqueio: boolean) {
        return bloqueio ? (
          <Tag color='red'>Bloqueado</Tag>
        ) : (
          <Tag color='blue'>Desbloqueado</Tag>
        );
      },
    },
    {
      title: 'Ativo',
      dataIndex: 'ativo',
      key: 'ativo ',
      render(ativo: boolean) {
        return ativo ? <Tag color='blue'>Ativo</Tag> : <Tag color='red'>Inativo</Tag>;
      },
    },
    {
      title: 'Ação',
      dataIndex: 'id',
      fixed: 'right',
      key: 'acao',
      render(id: string) {
        return (
          <>
            <ModalUsuario
              quandoFinalizar={() => {
                setReload(!reload);
              }}
              idUsuario={id}
            ></ModalUsuario>
          </>
        );
      },
    },
  ];

  function trataSwitch() {
    setAtivo(!ativo);
  }

  return (
    <>
      <App
        quandoFinalizar={() => {
          setReload(!reload);
        }}
      ></App>
      <Form autoComplete={'off'} layout={'horizontal'} onFinish={async (user: any) => {}}>
        <Col xs={24}>
          <Row justify={'start'}></Row>
        </Col>
        <Table
          loading={fetching}
          pagination={{ pageSize: 20 }}
          dataSource={data}
          columns={columns}
          scroll={{ x: '1190' }}
        />
      </Form>
    </>
  );
}
