import { notification } from 'antd';
import axios from 'axios';
import Service from '../sdk/Service';

import AuthService from './Authorization.service';

Service.setRequestInterceptors(async (request) => {
  const accessToken = AuthService.getAccessToken();

  // injeta o token de acesso na requisição
  if (accessToken) {
    if (request.baseURL === 'https://auth2-tech.herokuapp.com') {
      if (request.headers) {
        request.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }

    if (request.baseURL === 'https://techfood-api.herokuapp.com') {
      if (request.headers) {
        request.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }

    if (request.baseURL === 'https://technfe-api.herokuapp.com') {
      if (request.headers) {
        request.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }
  }
  return request;
});

Service.setResponseInterceptors(
  (response) => response,
  async (error) => {
    // recupera informações da requisição
    const originalRequest = error.config;

    if (error?.response?.status === 401) {
      AuthService.imperativelySendToLogout();
    }

    if (error?.response?.status === 400) {
      console.log(error.response);
      notification.error({
        message: error.response.data.detail,
        top: 80,
      });
    }

    if (error?.response?.status === 406) {
      return error.response;
    }

    if (error?.response?.status === 500) {
      notification.error({
        message: error.response.data.detail,
        top: 80,
      });
    }

    if (error?.response?.status === 409) {
      console.log(error.response);
      notification.error({
        message: error.response.data.detail,
        top: 80,
      });
    }

    throw error;
  }
);
