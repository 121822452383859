import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { group } from 'console';
import AuthService from '../../auth/Authorization.service';
import { UserService } from '../../sdk';
import { Usuarios } from '../../sdk/@types/Usuarios';

type PA<T> = PayloadAction<T>;

interface AuthState {
  user: Usuarios.UsuarioDetalhadoGrupo | null;
  fetching: boolean;
}

const initialState: AuthState = {
  user: null,
  fetching: false,
};

export const fetchUser = createAsyncThunk(
  'auth/fetchUser',
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const user = await UserService.getUsuarioDetalhado(userId);

      const grupos = await UserService.getGrupoUsuario(userId);

      /*grupos._embedded.grupos.map((grup) => {
        if (grup.nome === 'Vendedor') {
          window.alert('Você não tem acesso a este sistema!');
          AuthService.imperativelySendToLogout();
          return;
        }
      });*/

      const usuarioCompleto = {
        usuario: user,
        grupos: grupos,
      };

      dispatch(storeUser(usuarioCompleto));
    } catch (err) {
      return rejectWithValue({ ...(err as object) });
    }
  }
);

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    storeUser(state, action: PA<Usuarios.UsuarioDetalhadoGrupo>) {
      state.user = action.payload;
    },
    clearUser(state) {
      state.user = null;
    },
  },
});

export const { storeUser, clearUser } = authSlice.actions;

const authReducer = authSlice.reducer;
export default authReducer;
