import { Col, Form, Input, Row, Select, Button, notification, Switch } from 'antd';
import TabelaEstados from '../../componentes/TabelaEstados';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';

function ConsultaEstadosView() {
  usePageTitle('Lista de Estados');
  useBreadcrumb('Home/Estados/Consulta');

  return <TabelaEstados></TabelaEstados>;
}

export default ConsultaEstadosView;
