import TabelaCidades from '../../componentes/TabelaCidades';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';

function ConsultaCidadesView() {
  usePageTitle('Lista de Cidades');
  useBreadcrumb('Home/Cidades/Consulta');

  return <TabelaCidades></TabelaCidades>;
}

export default ConsultaCidadesView;
