import { Menu, Layout, Drawer, DrawerProps, Button } from 'antd';
import {
  UserOutlined,
  LaptopOutlined,
  DiffOutlined,
  HomeOutlined,
  TableOutlined,
  PlusCircleOutlined,
  FallOutlined,
  RiseOutlined,
  MenuOutlined,
  ShopOutlined,
  AimOutlined,
  HeatMapOutlined,
  BankOutlined,
} from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useState } from 'react';
import { useMemo } from 'react';
import { SiderProps } from 'antd/lib/layout';
import { FileSearchOutlined } from '@ant-design/icons/lib/icons';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function DefaultLayoutSidebar() {
  const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const { lg } = useBreakpoint();

  const history = useHistory();
  const location = useLocation();

  const [show, setShow] = useState(false);

  const SidebarWrapper: React.FC = useMemo(() => (lg ? Sider : Drawer), [lg]);

  const siderProps = useMemo((): SiderProps => {
    return {
      width: 200,
      className: 'site-layout-background no-print',
    };
  }, []);

  const drawerProps = useMemo((): DrawerProps => {
    return {
      visible: show,
      closable: true,
      title: <></>,
      headerStyle: {
        height: 64,
      },
      bodyStyle: {
        padding: 0,
      },
      onClose() {
        setShow(false);
      },
      placement: 'left',
    };
  }, [show]);

  const sidebarProps = useMemo(() => {
    return lg ? siderProps : drawerProps;
  }, [lg, siderProps, drawerProps]);

  function atualizarHistorico(caminho: string) {
    history.push(caminho);
  }

  return (
    <>
      {!lg && (
        <Button
          icon={<MenuOutlined />}
          onClick={() => setShow(true)}
          type={'text'}
          style={{ position: 'fixed', top: 0, left: 0, height: 64, zIndex: 99 }}
        />
      )}
      <SidebarWrapper {...sidebarProps}>
        <Menu
          mode='inline'
          defaultSelectedKeys={[location.pathname]}
          defaultOpenKeys={[location.pathname.split('/')[1]]}
          style={{ height: '100%', borderRight: 0 }}
        >
          <Menu.Item
            key={'/'}
            icon={<HomeOutlined />}
            onClick={function (event) {
              setShow(false);
            }}
          >
            <Link to={'/'}>Home</Link>
          </Menu.Item>
          <SubMenu key='usuarios' icon={<UserOutlined />} title='Usuários'>
            <Menu.Item
              key='/usuarios'
              onClick={function (event) {
                atualizarHistorico('/usuarios');
                setShow(false);
              }}
              icon={<FileSearchOutlined />}
            >
              <Link to={'/usuarios'}>Consulta</Link>
            </Menu.Item>
            <Menu.Item
              key='/usuarios/cadastro'
              onClick={function (event) {
                atualizarHistorico('/usuarios/cadastro');
                setShow(false);
              }}
              icon={<PlusCircleOutlined />}
            >
              <Link to={'/usuarios/cadastro'}>Cadastro</Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu key='lojas' icon={<ShopOutlined />} title='Lojas'>
            <Menu.Item
              key='/lojas'
              onClick={function (event) {
                atualizarHistorico('/lojas');
                setShow(false);
              }}
              icon={<FileSearchOutlined />}
            >
              <Link to={'/lojas'}>Consulta</Link>
            </Menu.Item>
            <Menu.Item
              key='/lojas/cadastro'
              onClick={function (event) {
                atualizarHistorico('/lojas/cadastro');
                setShow(false);
              }}
              icon={<PlusCircleOutlined />}
            >
              <Link to={'/lojas/cadastro'}>Cadastro</Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu key='ramo' icon={<AimOutlined />} title='Ramo'>
            <Menu.Item
              key='/ramo'
              onClick={function (event) {
                atualizarHistorico('/ramo');
                setShow(false);
              }}
              icon={<FileSearchOutlined />}
            >
              <Link to={'/ramo'}>Consulta</Link>
            </Menu.Item>
            <Menu.Item
              key='/ramo/cadastro'
              onClick={function (event) {
                atualizarHistorico('/ramo/cadastro');
                setShow(false);
              }}
              icon={<PlusCircleOutlined />}
            >
              <Link to={'/ramo/cadastro'}>Cadastro</Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu key='estado' icon={<HeatMapOutlined />} title='Estado'>
            <Menu.Item
              key='/estado'
              onClick={function (event) {
                atualizarHistorico('/estado');
                setShow(false);
              }}
              icon={<FileSearchOutlined />}
            >
              <Link to={'/estado'}>Consulta</Link>
            </Menu.Item>
            <Menu.Item
              key='/estado/cadastro'
              onClick={function (event) {
                atualizarHistorico('/estado/cadastro');
                setShow(false);
              }}
              icon={<PlusCircleOutlined />}
            >
              <Link to={'/estado/cadastro'}>Cadastro</Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu key='cidade' icon={<BankOutlined />} title='Cidade'>
            <Menu.Item
              key='/cidade'
              onClick={function (event) {
                atualizarHistorico('/cidade');
                setShow(false);
              }}
              icon={<FileSearchOutlined />}
            >
              <Link to={'/cidade'}>Consulta</Link>
            </Menu.Item>
            <Menu.Item
              key='/cidade/cadastro'
              onClick={function (event) {
                atualizarHistorico('/cidade/cadastro');
                setShow(false);
              }}
              icon={<PlusCircleOutlined />}
            >
              <Link to={'/cidade/cadastro'}>Cadastro</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </SidebarWrapper>
    </>
  );
}
