import { Card, Space, Table, Tag } from 'antd';
import { Col, Form, Input, Row, Select, Button, notification, Switch } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useCallback, useEffect, useState } from 'react';
import useBreadcrumb from '../core/hooks/useBreadcrumb';
import { UserService } from '../sdk';
import { Usuarios } from '../sdk/@types/Usuarios';
import {
  EyeOutlined,
  EditOutlined,
  SearchOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import LojaService from '../sdk/services/Loja.service';
import { useParams } from 'react-router-dom';
import App from './Modal';
import ModalEmissor from './ModalEmissor';
import { Lojas } from '../sdk/@types/Restaurantes';
import ModalT from './ModalT';
import ModalCadastroEmissor from './ModalCadastroEmissor';
import { formatRelativeWithOptions } from 'date-fns/fp';

interface TabelaEmissorProps {
  cnpj: string;
  aoCadastrar: () => any;
}
export default function TabelaEmissor(props: TabelaEmissorProps) {
  useBreadcrumb('Home/Lojas/Editar');
  const [emissor, setEmissor] = useState<Lojas.EmissorResumido[]>();
  const [ativo, setAtivo] = useState<boolean>(true);
  const [fetching, setFetching] = useState<boolean>(true);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const openCreationModal = useCallback(() => setShowCreationModal(true), []);
  const closeCreationModal = useCallback(() => setShowCreationModal(false), []);

  const [reload, setReload] = useState<boolean>(true);

  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (props.cnpj !== '0') {
      let cnpjAux = props.cnpj.replaceAll('.', '');
      cnpjAux = cnpjAux.replaceAll('/', '');
      cnpjAux = cnpjAux.replaceAll('-', '');
      LojaService.getEmissorByCnpj(cnpjAux)
        .then(trataLojaEmissor)
        .then(setEmissor)
        .catch((error) => {
          setError(new Error(error.message));
        });
      setFetching(false);
    } else {
      setEmissor([]);
      setFetching(false);
    }
  }, [reload]);

  function trataLojaEmissor(dados: Lojas.Emissor): Lojas.EmissorResumido[] {
    if(dados){
      const emissorAux: Lojas.EmissorResumido = {
        id: dados.id,
        chaveDeAcesso: dados.chaveDeAcesso,
        chaveHomolog: dados.chaveHomolog,
        ativo: dados.ativo,
        cnpj: dados.cnpj,
        status_emissao: dados.status_emissao,
      };
      const emissorArray: Lojas.EmissorResumido[] = Array();
      emissorArray.push(emissorAux);
      setFetching(false);
      return emissorArray;
    }else{
      setFetching(false);
      return [];
    }



  
  }

  const getColumnSearchProps = (
    dataIndex: keyof Usuarios.UsuarioTratado,
    displayName?: string
  ): ColumnProps<any> => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
      <Card>
        <Input
          style={{ marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => confirm()}
        />
        <Space>
          <Button
            type={'primary'}
            size={'small'}
            style={{ width: 90 }}
            onClick={() => confirm()}
            icon={<SearchOutlined />}
          >
            Buscar
          </Button>

          <Button onClick={clearFilters} size={'small'} style={{ width: 90 }}>
            Limpar
          </Button>
        </Space>
      </Card>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#0099ff' : undefined }} />
    ),
    // @ts-ignore
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : '',
  });

  const columns: any = [
    {
      title: 'Chave de acesso',
      dataIndex: 'chaveDeAcesso',
      key: 'nome',
    },
    {
      title: 'Chave Homologação',
      dataIndex: 'chaveHomolog',
      key: 'chaveHomolog',
    },
    {
      title: 'Ativo',
      dataIndex: 'ativo',
      key: 'ativo ',
      render(ativo: boolean) {
        return ativo ? <Tag color='blue'>Ativo</Tag> : <Tag color='red'>Inativo</Tag>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status_emissao',
      key: 'status_emissao',
      render(status_emissao: string) {
        return status_emissao == '0' ? (
          <Tag color='blue'>Todos</Tag>
        ) : status_emissao == '1' ? (
          <Tag color='blue'>Aberto</Tag>
        ) : status_emissao == '2' ? (
          <Tag color='blue'>Emissão</Tag>
        ) : status_emissao == '3' ? (
          <Tag color='blue'>Produção</Tag>
        ) : status_emissao == '4' ? (
          <Tag color='blue'>Entrega</Tag>
        ) : (
          ''
        );
      },
    },
    {
      title: 'Ação',
      dataIndex: 'cnpj',
      fixed: 'right',
      key: 'acao',
      render(cnpj: string) {
        return (
          <>
            <ModalEmissor
              quandoFinalizar={() => {
                setReload(!reload);
              }}
              cnpj={cnpj}
            ></ModalEmissor>
          </>
        );
      },
    },
  ];

  function trataSwitch() {
    setAtivo(!ativo);
  }
console.log(emissor);
  return (
    <>
      { emissor  &&  emissor.length > 0 ? (
        <ModalCadastroEmissor
          quandoFinalizar={() => {
            setReload(!reload);
          }}
          aoCadastrar={props.aoCadastrar}
          emissor={emissor}
          cnpj={props.cnpj ? props.cnpj : '0'}
        ></ModalCadastroEmissor>
      ) : (
        <ModalCadastroEmissor
          quandoFinalizar={() => {
            setReload(!reload);
          }}
          aoCadastrar={props.aoCadastrar}
          cnpj={props.cnpj ? props.cnpj : '0'}
        ></ModalCadastroEmissor>
      )}

      <Col xs={24}>
        <Row justify={'start'}></Row>
      </Col>
      <Table
        loading={fetching}
        pagination={{ pageSize: 20 }}
        dataSource={emissor ? emissor : []}
        columns={columns}
        scroll={{ x: '1190' }}
      />
    </>
  );
}
