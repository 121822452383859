import { Cidades } from '../@types/Cidades';

import Service from '../Service';

class CidadeService extends Service {
  static getAll() {
    return this.HttpTechFood.get<Cidades.Cidade>(`/v1/cidades`).then(this.getData);
  }

  static listarPorEstado(idEstado: string) {
    return this.HttpTechFood.get<Cidades.Cidade>(
      `/v1/cidades/listarPorEstado/${idEstado}`
    ).then(this.getData);
  }

  static atualizarCidade(data: Cidades.formCidadesInput, cidadeId: string) {
    return this.HttpTechFood.put<any>(`/v1/cidades/${cidadeId}`, data).then(this.getData);
  }

  static cadastrarCidade(data: Cidades.formCidadesInput) {
    return this.HttpTechFood.post<any>(`/v1/cidades`, data).then(this.getData);
  }

  static getById(cidadeId: string) {
    return this.HttpTechFood.get<Cidades.GradeCidades>(`/v1/cidades/${cidadeId}`).then(
      this.getData
    );
  }

  static excluirCidadeByID(cidadeId: string) {
    return this.HttpTechFood.delete<any>(`/v1/cidades/${cidadeId}`, {}).then(
      this.getData
    );
  }
}

export default CidadeService;
