// User.service.ts
import { Cozinhas } from '../@types/Cozinha';
import { Lojas } from '../@types/Restaurantes';

import Service from '../Service';

class CozinhaService extends Service {
  static getAll() {
    return this.HttpTechFood.get<Cozinhas.Cozinha>(`/v1/cozinhas`).then(this.getData);
  }

  static getById(cozinhaId: string) {
    return this.HttpTechFood.get<Cozinhas.GradeRamos>(`/v1/cozinhas/${cozinhaId}`).then(
      this.getData
    );
  }

  static cadastrarRamo(data: Cozinhas.GradeRamos) {
    return this.HttpTechFood.post<Lojas.RestauranteModel>('/v1/cozinhas', data).then(
      this.getData
    );
  }
  static atualizarRamo(data: Cozinhas.GradeRamos, cozinhaId: string) {
    return this.HttpTechFood.put<Lojas.RestauranteModel>(
      `/v1/cozinhas/${cozinhaId}`,
      data
    ).then(this.getData);
  }
}

export default CozinhaService;
