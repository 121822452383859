import { Col, Form, Input, Row, Select, Button, notification, Switch } from 'antd';
import TabelaLojas from '../../componentes/TabelaLojas';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';

function ConsultaLojasView() {
  usePageTitle('Lista de Lojas');
  useBreadcrumb('Home/Lojas/Consulta');

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginTop: '-35px',
          fontWeight: 'bold',
        }}
      >
        ATENÇÃO: Utilizar Para Criar Usuário Proprietário da Loja (Que vai usar o Manager)
      </div>
      <div style={{ width: '100%', height: '15px' }}></div>
      <TabelaLojas></TabelaLojas>;
    </>
  );
}

export default ConsultaLojasView;
