import { Col, Form, Input, Row, Select, Button, notification, Switch, Space } from 'antd';
import App from '../../componentes/Modal';
import TabelaLojas from '../../componentes/TabelaLojas';
import TabelaEmissor from '../../componentes/TabelaEmissor';
import TabelaUsuariosPorLoja from '../../componentes/TabelaUsuariosPorLoja';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { userInfo } from 'os';
import { useEffect, useState } from 'react';
import { Authentication } from '../../auth/Auth';
import { Lojas } from '../../sdk/@types/Restaurantes';
import LojaService from '../../sdk/services/Loja.service';
import { Cidades } from '../../sdk/@types/Cidades';
import CidadeService from '../../sdk/services/Cidade.service';
import CozinhaService from '../../sdk/services/Cozinha.service';
import { Cozinhas } from '../../sdk/@types/Cozinha';
import { UserService, Usuarios } from '../../sdk';
import TabelaTerminaisPorEmissor from '../../componentes/TabelaTerminaisPorEmissor';
import { Estados } from '../../sdk/@types/Estados';
import EstadoService from '../../sdk/services/Estado.service';

interface FormularioDeEstadoProps {
  onSuccess: () => any;
  estado?: Estados.GradeEstados;
}

export default function FormularioDeEstado(props: FormularioDeEstadoProps) {
  usePageTitle('Lista de Estados');
  const access_token = localStorage.getItem('accessToken');
  const [loading, setLoading] = useState<boolean>(false);

  const params = useParams<{ id: string }>();
  const decodedToken: Authentication.AccessTokenDecodedBody = jwtDecode(
    access_token as string
  );

  const { onSuccess } = props;

  const [error, setError] = useState<Error>();

  const history = useHistory();

  return (
    <>
      <Form
        autoComplete={'off'}
        layout={'horizontal'}
        onFinish={async (estado: Estados.formEstados) => {
          if (params.id) {
            setLoading(true);
            let estadoAux = await EstadoService.atualizarEstado(estado, params.id);
            setLoading(false);
            if (estadoAux) {
              onSuccess();
              notification.success({
                message: 'Sucesso',
                description: 'Estado atualizado com sucesso',
                top: 90,
              });
            }
          } else {
            setLoading(true);
            let estadoAux = await EstadoService.cadastrarEstado(estado);
            setLoading(false);
            onSuccess();
            notification.success({
              message: 'Sucesso',
              description: 'Estado criado com sucesso',
              top: 90,
            });
            history.push(`/estado/editar/${estadoAux.id}`);
          }
        }}
        initialValues={props.estado}
      >
        <Row align={'middle'}>
          <legend
            style={{
              borderTop: '1px solid #000',
              margin: '0',
              borderBottom: '0',
              borderLeft: '0',
              borderRight: '0',
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            {'Identificação'}
          </legend>
          <fieldset
            style={{
              width: ' 100%',
              display: 'flex',
              padding: '20px',
              gap: '10px',
            }}
          ></fieldset>
          <Col xs={24} lg={12}>
            <Form.Item
              label={'Nome'}
              name={'nome'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
                {
                  max: 255,
                  message: `O nome não pode ter mais de 255 caracteres`,
                },
              ]}
            >
              <Input
                style={{
                  width: '95%',
                }}
                placeholder={'E.x.: São Paulo'}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={'UF'}
              name={'uf'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
                {
                  max: 255,
                  message: `O nome não pode ter mais de 255 caracteres`,
                },
              ]}
            >
              <Input
                style={{
                  width: '95%',
                }}
                placeholder={'E.x.: SP'}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={'cUf'}
              name={'cuf'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
                {
                  max: 255,
                  message: `O nome não pode ter mais de 255 caracteres`,
                },
              ]}
            >
              <Input
                style={{
                  width: '95%',
                }}
                type={'number'}
                placeholder={'E.x.: 35'}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Space size={8}>
              <Button
                type={'primary'}
                htmlType={'submit'}
                loading={loading}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              >
                Salvar Estado
              </Button>

              <Button
                type={'primary'}
                htmlType={'button'}
                style={{
                  backgroundColor: props.estado ? 'red' : '#E5E5E5',
                  borderColor: props.estado ? 'red' : '#E5E5E5',
                }}
                disabled={props.estado ? false : true}
                onClick={async () => {
                  if (props.estado) {
                    const respExclusão = await EstadoService.excluirEstadoByID(
                      props.estado.id as string
                    );
                    notification.success({
                      message: 'Sucesso',
                      description: 'Estado excluido com sucesso',
                      top: 80,
                    });
                    history.push(`/estado`);
                  }
                }}
              >
                Excluir Estado
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
}
