import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserService } from '../../sdk';
import { Cozinhas } from '../../sdk/@types/Cozinha';
import { Lojas } from '../../sdk/@types/Restaurantes';
import CozinhaService from '../../sdk/services/Cozinha.service';
import LojaService from '../../sdk/services/Loja.service';

export default function useRamo() {
  const [ramo, setRamo] = useState<Cozinhas.GradeRamos>();

  const fetchRamo = useCallback((cozinhaId: string) => {
    CozinhaService.getById(cozinhaId).then(tratarDadosRamo).then(setRamo);
  }, []);

  function tratarDadosRamo(dados: Cozinhas.GradeRamos): Cozinhas.GradeRamos {
    return dados;
  }

  return {
    ramo,
    fetchRamo,
  };
}
