import { Skeleton } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from '../../core/hooks/useAuth';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import useLojaUsuario from '../../core/hooks/useUserLoja';
import FormularioUsuario from '../features/FormularioUsuario';

function EdicaoDeUsuarioView() {
  usePageTitle('Edição de Usuario');
  useBreadcrumb('Home/Usuarios/Edicao');
  const parametroUrl = useParams<{ id: string }>();
  const { usuarioLoja, fetchUsuarioLoja } = useLojaUsuario();

  useEffect(() => {
    // chamar consulta do usuario novamente
    fetchUsuarioLoja(parametroUrl.id);
  }, [fetchUsuarioLoja]);

  if (!usuarioLoja) return <Skeleton />;

  return (
    <FormularioUsuario onSuccess={() => {}} usuarioLoja={usuarioLoja}></FormularioUsuario>
  );
}

export default EdicaoDeUsuarioView;
