import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import FormularioDeEstado from '../features/FormularioDeEstado';

export default function CadastroDeEstadosView() {
  useBreadcrumb('Home/Estados/Cadastro');
  return (
    <>
      <FormularioDeEstado onSuccess={() => {}} />
    </>
  );
}
