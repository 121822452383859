import { Skeleton } from 'antd';
import { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import useRamo from '../../core/hooks/useRamo';
import FormularioDeLoja from '../features/FormularioDeLoja';
import FormularioDeRamo from '../features/FormularioDeRamo';

export default function EdicaoDeRamoView() {
  useBreadcrumb('Home/Ramo/EditarRamo');
  const parametroUrl = useParams<{ id: string }>();
  const { ramo, fetchRamo } = useRamo();

  useEffect(() => {
    fetchRamo(parametroUrl.id);
  }, [fetchRamo]);

  if (!ramo) return <Skeleton />;

  return (
    <>
      <FormularioDeRamo onSuccess={() => {}} ramo={ramo} />
    </>
  );
}
