import { Skeleton } from 'antd';
import { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import useEstado from '../../core/hooks/useEstado';
import FormularioDeEstado from '../features/FormularioDeEstado';

export default function EdicaoDeEstadoView() {
  useBreadcrumb('Home/Ramo/EditarRamo');
  const parametroUrl = useParams<{ id: string }>();
  const { estado, fetchEstado } = useEstado();

  useEffect(() => {
    fetchEstado(parametroUrl.id);
  }, [fetchEstado]);

  if (!estado) return <Skeleton />;

  return (
    <>
      <FormularioDeEstado onSuccess={() => {}} estado={estado} />
    </>
  );
}
