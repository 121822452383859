import { Col, Form, Input, Row, Select, Button, notification, Switch } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { UserService } from '../../sdk';
import { Usuarios } from '../../sdk/@types/Usuarios';
import jwtDecode from 'jwt-decode';
import { Authentication } from '../../auth/Auth';
import { userInfo } from 'os';
import { useCallback, useEffect, useState } from 'react';
import LojaService from '../../sdk/services/Loja.service';
import { Lojas } from '../../sdk/@types/Restaurantes';
import MaskedInput from 'antd-mask-input';
import { setgroups } from 'process';
import Checkbox from 'antd/lib/checkbox';
import Divider from 'antd/lib/divider';

export default function FormularioUsuario(props: {
  onSuccess: () => any;
  usuarioLoja?: Usuarios.UsuarioDetalhado;
  modal?: boolean;
}) {
  const access_token = localStorage.getItem('accessToken');
  const [sugestao, setSugestao] = useState<string>('');
  const [grupos, setGrupos] = useState<any>([]);
  const [gruposUsuario, setGruposUsuario] = useState<any>([]);

  const [carregarSugestao, setCarregarSugestao] = useState<boolean>(true);

  useEffect(() => {
    UserService.getAllGrupos().then(setGrupos);
  }, []);

  useEffect(() => {
    if (props?.usuarioLoja?.id) {
      UserService.getGrupoUsuario(props?.usuarioLoja?.id).then((resp) => {
        console.log(resp?._embedded?.grupos);
        const arrayGruposUsuario: any[] = [];
        resp?._embedded?.grupos.map((grupo) => {
          arrayGruposUsuario.push(grupo.id);
        });
        setCheckedList(arrayGruposUsuario);
        setCheckedListOriginal(arrayGruposUsuario);
      });
    }
  }, [props?.usuarioLoja?.id]);

  const parametroUrl = useParams<{ id: string }>();
  const idLoja: string = parametroUrl.id ? parametroUrl.id : '0';
  const decodedToken: Authentication.AccessTokenDecodedBody = jwtDecode(
    access_token as string
  );
  const [fetching, setFetching] = useState<boolean>(false);

  const { onSuccess } = props;
  let bloqueioAux: boolean = props.usuarioLoja ? props.usuarioLoja.bloqueio : false;

  const [checkedAtivo, setCheckedAtivo] = useState(
    props.usuarioLoja ? props.usuarioLoja.ativo : true
  );
  const [checkedBloqueio, setCheckedBloqueio] = useState(bloqueioAux);
  if (props.usuarioLoja) {
    props.usuarioLoja.fone = props.usuarioLoja.fone.replaceAll('(', '');
    props.usuarioLoja.fone = props.usuarioLoja.fone.replaceAll(')', '');
    props.usuarioLoja.fone = props.usuarioLoja.fone.replace('55', '');
    props.usuarioLoja.fone = props.usuarioLoja.fone.replaceAll('-', '');
  }
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [checkedListOriginal, setCheckedListOriginal] = useState<string[]>([]);

  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState<boolean>(false);

  const onChange = (checkedValues: any) => {
    setCheckedList(checkedValues);
    setIndeterminate(checkedValues.length > 0 && checkedValues.length < options.length);
    setCheckAll(checkedValues.length === options.length);
    console.log(checkedValues);
    console.log(props.usuarioLoja);
  };

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? options.slice(1) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const history = useHistory();

  const options: string[] = [
    'Opção 1',
    'Opção 2',
    'Opção 3',
    'Opção 4',
    'Opção 5',
    'Opção 6',
    'Opção 7',
    'Opção 8',
    'Opção 9',
    'Opção 10',
    'Opção 11',
    'Opção 12',
    'Opção 13',
    'Opção 14',
    'Opção 15',
    'Opção 16',
    'Opção 17',
    'Opção 18',
    'Opção 19',
    'Opção 20',
  ];

  return (
    <Form
      autoComplete={'off'}
      layout={'horizontal'}
      onFinish={async (user: Usuarios.Input) => {
        setFetching(true);
        user.ativo = checkedAtivo;
        user.bloqueio = checkedBloqueio;

        try {
          let idUsu: any;
          if (props.usuarioLoja) {
            user.fone = '55' + props.usuarioLoja.fone;
            user.usuario = props.usuarioLoja.usuario;
            idUsu = await UserService.editUser(user, props.usuarioLoja?.id);

            const selecionar = checkedList.filter(
              (item) => !checkedListOriginal.includes(item)
            );
            const remover = checkedListOriginal.filter(
              (item) => !checkedList.includes(item)
            );

            selecionar.map((idGrupo) => {
              UserService.associarGrupo(idUsu.id, idGrupo);
            });

            remover.map((idGrupo) => {
              UserService.desassociarGrupo(idUsu.id, idGrupo);
            });

            /*   if (user.grupo) {
              UserService.associarGrupo(idUsu.id, user.grupo);
            }*/
            onSuccess();
          } else {
            let userGrupoAux;
            if (user.grupo) {
              userGrupoAux = user.grupo;
            } else {
              userGrupoAux = '7';
            }
            idUsu = await UserService.inserirUsuarioComGrupo(user, userGrupoAux);

            if (idUsu.status === 406) {
              notification.error({
                message: idUsu.detail + 'teste',
                top: 90,
              });

              let sugestaoAux = JSON.parse(idUsu.userMessage);

              setSugestao(
                'Algumas alternativas: ' +
                  sugestaoAux[0] +
                  ', ' +
                  sugestaoAux[1] +
                  ', ' +
                  sugestaoAux[2]
              );
              setCarregarSugestao(false);
              setCarregarSugestao(true);
              setFetching(false);
            } else {
              if (idLoja != '0') {
                LojaService.associarLoja(idLoja, idUsu.id);
                setFetching(false);
                history.push(`/lojas/editar/${idLoja}`);
              } else {
                setFetching(false);
                history.push(`/usuarios`);
              }

              onSuccess();
              notification.success({
                message: 'Sucesso',
                description: 'usuário criado com sucesso',
              });
            }
          }
        } catch (error) {
          setFetching(false);
        }
      }}
      initialValues={props.usuarioLoja}
    >
      <Row align={'top'} gutter={24}>
        {props.modal ? (
          <Col xs={24} lg={24}>
            <Form.Item
              label={'Nome'}
              name={'nome'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
                {
                  max: 255,
                  message: `O nome não pode ter mais de 255 caracteres`,
                },
              ]}
            >
              <Input
                placeholder={'E.x.: João Silva'}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              />
            </Form.Item>

            <Form.Item
              label={'E-mail'}
              name={'email'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
                {
                  max: 255,
                  message: `O email não pode ter mais de 255 caracteres`,
                },
              ]}
            >
              <Input
                type='email'
                placeholder={'E.x.: contato@joao.silva'}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              />
            </Form.Item>
          </Col>
        ) : (
          <Col xs={24} lg={12}>
            <Form.Item
              label={'Nome'}
              name={'nome'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
                {
                  max: 255,
                  message: `O nome não pode ter mais de 255 caracteres`,
                },
              ]}
            >
              <Input
                placeholder={'E.x.: João Silva'}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              />
            </Form.Item>

            <Form.Item
              label={'E-mail'}
              name={'email'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
                {
                  max: 255,
                  message: `O email não pode ter mais de 255 caracteres`,
                },
              ]}
            >
              <Input
                type='email'
                placeholder={'E.x.: contato@joao.silva'}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              />
            </Form.Item>
          </Col>
        )}

        {!props.usuarioLoja ? (
          <Col xs={24} lg={12}>
            <Form.Item
              label={'Telefone'}
              name={'fone'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
                {
                  max: 20,
                  message: `O telefone não pode ter mais de 20 caracteres`,
                },
              ]}
            >
              <MaskedInput
                mask='(11) 11111-1111'
                placeholder={'(11) 99999-0000'}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              />
            </Form.Item>

            <Form.Item label={'Grupo'} name={'grupo'}>
              <Select
                placeholder={'Selecione o grupo'}
                defaultValue={'5'}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              >
                {grupos._embedded
                  ? grupos._embedded.grupos.map((grupo: any) => (
                      <>
                        <Select.Option value={grupo.id}> {grupo.nome}</Select.Option>
                      </>
                    ))
                  : ''}
              </Select>
            </Form.Item>
          </Col>
        ) : (
          ''
        )}

        <Col xs={24} lg={12}>
          {carregarSugestao ? (
            <h2 style={{ fontSize: '12px', marginLeft: '72px' }}>{sugestao}</h2>
          ) : (
            ''
          )}
          {!props.usuarioLoja ? (
            <Form.Item
              label={'Usuário'}
              name={'usuario'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
                {
                  max: 255,
                  message: `O usuario não pode ter mais de 255 caracteres`,
                },
              ]}
            >
              <Input
                placeholder={'E.x.: xxxxxxxx'}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              />
            </Form.Item>
          ) : (
            ''
          )}
          {!props.usuarioLoja ? (
            <Form.Item
              label={'Senha'}
              name={'senha'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
              ]}
            >
              <Input.Password
                placeholder='Senha'
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              />
            </Form.Item>
          ) : (
            ''
          )}
        </Col>

        <Col xs={24} lg={12}>
          <Row justify={'center'} style={{ gap: '5px' }}>
            <Form.Item label='Status' name={'ativo'}>
              <Switch
                checkedChildren={'Ativo'}
                unCheckedChildren={'Inativo'}
                defaultChecked
                checked={checkedAtivo}
                onChange={setCheckedAtivo}
              />
            </Form.Item>

            <Form.Item label='Situação' name={'bloqueio'}>
              <Switch
                onChange={setCheckedBloqueio}
                checked={checkedBloqueio}
                checkedChildren={'Bloqueado'}
                unCheckedChildren={'Desbloqueado'}
              />
            </Form.Item>
          </Row>
        </Col>
        {props.usuarioLoja ? (
          <Col xs={24} lg={24} style={{ marginBottom: '20px' }}>
            <h2 style={{ fontWeight: 'bold' }}>Grupos</h2>
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={onChange}
              value={checkedList}
            >
              <Row gutter={[16, 16]}>
                {grupos?._embedded?.grupos.map((option: any, index: any) =>
                  option.id !== '5' ? (
                    <Col span={6} key={index}>
                      <Checkbox value={option.id}>{option.nome}</Checkbox>
                    </Col>
                  ) : null
                )}
              </Row>
            </Checkbox.Group>
          </Col>
        ) : (
          ''
        )}
        <Col xs={24}>
          <Row justify={'end'}>
            <Button
              type={'primary'}
              htmlType={'submit'}
              loading={fetching}
              disabled={
                !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
              }
            >
              Salvar
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
