import { Card, Space, Table, Tag, Tooltip } from 'antd';
import { Col, Form, Input, Row, Select, Button, notification, Switch } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import useBreadcrumb from '../core/hooks/useBreadcrumb';
import { UserService } from '../sdk';
import { Usuarios } from '../sdk/@types/Usuarios';
import {
  EyeOutlined,
  EditOutlined,
  SearchOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { idText } from 'typescript';

export default function TabelaLojas() {
  const [data, setData] = useState<Usuarios.GradeRestaurantes[]>();
  const [ativo, setAtivo] = useState<boolean>(true);
  const [fetching, setFetching] = useState<boolean>(true);

  const [error, setError] = useState<Error>();

  useEffect(() => {
    setFetching(true);
    UserService.getRestaurantesPorStatus(ativo)
      .then(tratarDados)
      .then(setData)
      .catch((error) => {
        setError(new Error(error.message));
      });
  }, [ativo]);

  function tratarDados(dados: Usuarios.ListaRestaurantes): Usuarios.GradeRestaurantes[] {
    if (dados._embedded == undefined) {
      setFetching(false);
      return [];
    } else {
      setFetching(false);
      return dados._embedded.restaurantes;
    }
  }

  const getColumnSearchProps = (
    dataIndex: keyof Usuarios.GradeRestaurantes,
    displayName?: string
  ): ColumnProps<Usuarios.GradeRestaurantes> => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
      <Card>
        <Input
          style={{ marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          //placeholder={`Buscar ${displayName || dataIndex}`}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => confirm()}
        />
        <Space>
          <Button
            type={'primary'}
            size={'small'}
            style={{ width: 90 }}
            onClick={() => confirm()}
            icon={<SearchOutlined />}
          >
            Buscar
          </Button>

          <Button onClick={clearFilters} size={'small'} style={{ width: 90 }}>
            Limpar
          </Button>
        </Space>
      </Card>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#0099ff' : undefined }} />
    ),
    // @ts-ignore
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : '',
  });

  const columns: any = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      ...getColumnSearchProps('nome', 'Nome'),
      key: 'nome',
    },
    {
      title: 'Domínio',
      dataIndex: 'dominio',
      ...getColumnSearchProps('dominio', 'Dominio'),
      key: 'dominio',
    },
    {
      title: 'Aberto',
      dataIndex: 'aberto',
      ...getColumnSearchProps('aberto', 'Aberto'),
      key: 'aberto',
      render(aberto: boolean) {
        return (
          <>
            {aberto ? (
              <Tag color='blue'>{'Aberto'}</Tag>
            ) : (
              <Tag color='red'>{'Fechado'}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: 'Bloqueado',
      dataIndex: 'bloqueio',
      key: 'bloqueio ',
      render(bloqueio: boolean) {
        return (
          <>
            {bloqueio ? (
              <Tag color='red'>{'Bloqueado'}</Tag>
            ) : (
              <Tag color='blue'>{'Desbloqueado'}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: 'Ação',
      dataIndex: 'id',
      fixed: 'right',
      key: 'acao',
      render(id: number) {
        return (
          <>
            <Button size='small' icon={<EyeOutlined />} />
            <Tooltip title={'Editar Loja'} placement={'right'}>
              <Link to={`/lojas/editar/${id}`}>
                <Button size='small' icon={<EditOutlined />}></Button>
              </Link>
            </Tooltip>
          </>
        );
      },
    },
  ];

  function trataSwitch() {
    setAtivo(!ativo);
  }

  return (
    <>
      <Form autoComplete={'off'} layout={'horizontal'} onFinish={async (user: any) => {}}>
        <Col xs={24}>
          <Row justify={'start'}>
            <Form.Item label='Status' valuePropName='checked'>
              <Switch
                onChange={trataSwitch}
                defaultChecked
                checkedChildren={'Ativos'}
                unCheckedChildren={'Inativos'}
              />
            </Form.Item>
          </Row>
        </Col>
        <Table
          loading={fetching}
          pagination={{ pageSize: 20 }}
          dataSource={data}
          columns={columns}
          scroll={{ x: '1190' }}
        />
      </Form>
    </>
  );
}
