import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import FormularioDeCidade from '../features/FormularioDeCidade';

export default function CadastroDeCidadesView() {
  useBreadcrumb('Home/Cidades/Cadastro');
  return (
    <>
      <FormularioDeCidade onSuccess={() => {}} />
    </>
  );
}
