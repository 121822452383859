import { ShopOutlined, SolutionOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import TabelaModelo from '../../componentes/TabelaModelo';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';

function HomeView() {
  usePageTitle('Home');
  useBreadcrumb('Home');
  const history = useHistory();

  function atualizarHistorico(caminho: string) {
    history.push(caminho);
  }

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={12}>
        <Button
          type={'primary'}
          htmlType={'button'}
          icon={<SolutionOutlined />}
          onClick={function (event) {
            atualizarHistorico('/usuarios');
          }}
          style={{
            height: '200px',
            width: '90%',
          }}
        >
          Consulta de Usuários
        </Button>
      </Col>
      <Col xs={24} lg={12}>
        <Button
          type={'primary'}
          htmlType={'button'}
          icon={<UserAddOutlined />}
          onClick={function (event) {
            atualizarHistorico('/usuarios/cadastro');
          }}
          style={{
            height: '200px',
            width: '90%',
          }}
        >
          Cadastro de Usuários
        </Button>
      </Col>
      <Col xs={24} lg={12}>
        <Button
          type={'primary'}
          htmlType={'button'}
          icon={<ShopOutlined />}
          onClick={function (event) {
            atualizarHistorico('/lojas');
          }}
          style={{
            height: '200px',
            width: '90%',
          }}
        >
          Consulta de Lojas
        </Button>
      </Col>
      <Col xs={24} lg={12}>
        <Button
          type={'primary'}
          htmlType={'button'}
          icon={<ShopOutlined />}
          onClick={function (event) {
            atualizarHistorico('/lojas/cadastro');
          }}
          style={{
            height: '200px',
            width: '90%',
          }}
        >
          Cadastro de Lojas
        </Button>
      </Col>
    </Row>
  );
}

export default HomeView;
