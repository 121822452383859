import { Col, Form, Input, Row, Select, Button, notification, Switch } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { UserService } from '../../sdk';
import { Usuarios } from '../../sdk/@types/Usuarios';
import jwtDecode from 'jwt-decode';
import { Authentication } from '../../auth/Auth';
import { userInfo } from 'os';
import { useCallback, useEffect, useState } from 'react';
import LojaService from '../../sdk/services/Loja.service';
import { Lojas } from '../../sdk/@types/Restaurantes';
import MaskedInput from 'antd-mask-input';

export default function FormularioUsuario(props: {
  onSuccess: () => any;
  terminalEmissor?: Lojas.TerminalTratado;
  idEmissor?: string;
}) {
  const access_token = localStorage.getItem('accessToken');
  const parametroUrl = useParams<{ id: string }>();
  const idLoja: number = parametroUrl.id ? parseInt(parametroUrl.id) : 0;
  const decodedToken: Authentication.AccessTokenDecodedBody = jwtDecode(
    access_token as string
  );

  const { onSuccess } = props;

  const [checkedAtivo, setCheckedAtivo] = useState(
    props.terminalEmissor ? props.terminalEmissor.ativo : true
  );
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  function trataJsonTerminal(
    terminal: Lojas.TerminalTratado,
    numeroUltimaEmissao: string
  ): Lojas.TerminalTratado {
    terminal.ultimoNumeroEmissao = numeroUltimaEmissao;

    const novoTerminal: Lojas.TerminalTratado = {
      id: terminal.id,
      serie: terminal.serie,
      ativo: terminal.ativo,
      ultimoNumeroEmissao: numeroUltimaEmissao,
      ultimoRetorno:terminal.ultimoRetorno
    };

    return novoTerminal;
  }

  return (
    <Form
      autoComplete={'off'}
      layout={'horizontal'}
      onFinish={async (novoTerminal: Lojas.TerminalTratado) => {
        console.log('Finalizei');
        //setLoading(true);
       try {
          if (props.terminalEmissor) {

            props.terminalEmissor.ultimoNumeroEmissao = novoTerminal.ultimoNumeroEmissao;
            if (props.idEmissor) {
              const bodyUpdateTerminal = {
                id: props.terminalEmissor.id,
                serie: props.terminalEmissor.serie,
                ativo: props.terminalEmissor.ativo,
                ultimoNumeroEmissao: props.terminalEmissor.ultimoNumeroEmissao,
                ultimoRetorno:props.terminalEmissor.ultimoRetorno
              };
              console.log(bodyUpdateTerminal);
  
              
              const resp = await LojaService.updateTerminal(
                bodyUpdateTerminal,
                props.idEmissor
              );

              if (resp.status == 200) {
                setLoading(false);
                onSuccess();
              }
            }
          }
        } catch (error) {
          setLoading(false);
        }
      }}
      initialValues={props.terminalEmissor}
    >
      <Row align={'top'} gutter={24}>
        <Col xs={24} lg={24}>
          <Form.Item
            label={'Último numero emissão'}
            name={'ultimoNumeroEmissao'}
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
            ]}
          >
            <Input placeholder={'E.x.: xxxxxxxx'} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Row justify={'end'}>
            <Button type={'primary'} htmlType={'submit'} loading={loading}>
              {'Salvar'}
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
