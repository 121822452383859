import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserService } from '../../sdk';
import { Cozinhas } from '../../sdk/@types/Cozinha';
import { Estados } from '../../sdk/@types/Estados';
import { Lojas } from '../../sdk/@types/Restaurantes';
import CozinhaService from '../../sdk/services/Cozinha.service';
import EstadoService from '../../sdk/services/Estado.service';
import LojaService from '../../sdk/services/Loja.service';

export default function useEstado() {
  const [estado, setEstado] = useState<Estados.GradeEstados>();

  const fetchEstado = useCallback((estadoId: string) => {
    EstadoService.getById(estadoId).then(tratarDadosEstado).then(setEstado);
  }, []);

  function tratarDadosEstado(dados: Estados.GradeEstados): Estados.GradeEstados {
    return dados;
  }

  return {
    estado,
    fetchEstado,
  };
}
