import { DeleteOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons';
import { Modal, Button, Row, Col, notification, Input, Form, Switch } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Lojas } from '../sdk/@types/Restaurantes';
import LojaService from '../sdk/services/Loja.service';
import useEmissor from '../core/hooks/useEmissor';
import Select from 'antd/lib/select';

const ModalEmissor = (props: { quandoFinalizar: () => any; cnpj?: string }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [ModalEmissor, setModalEmissor] = useState('Form de cadastro de usuários');
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const openCreationModal = useCallback(() => setShowCreationModal(true), []);
  const closeCreationModal = useCallback(() => setShowCreationModal(false), []);

  //fazer a busca
  const { emissor, fetchEmissor } = useEmissor();

  //fazer a busca
  useEffect(() => {
    // chamar consulta do emissor novamente
    fetchEmissor(props.cnpj ? props.cnpj : '0');
  }, [fetchEmissor]);

  const params = useParams<{ id: string }>();
  const [checkedAtivoEmissor, setCheckedAtivoEmissor] = useState(
    emissor ? emissor.ativo : true
  );

  return (
    <>
      <Row align={'middle'} gutter={24}>
        <Col xs={24} lg={12}>
          <Button size='small' icon={<EditOutlined />} onClick={openCreationModal} />
        </Col>
      </Row>
      <Modal
        title='Emissor'
        footer={null}
        visible={showCreationModal}
        onCancel={closeCreationModal}
        destroyOnClose
      >
        <Form
          autoComplete={'off'}
          layout={'horizontal'}
          initialValues={emissor}
          onFinish={async (emissorForm: Lojas.EmissorResumido) => {
            try {
              setLoading(true);
              if (emissor) {
                emissorForm.ativo = checkedAtivoEmissor;
                emissorForm.cnpj = emissor.cnpj;
                emissorForm.id = emissor.id;

                let emissorAux: any = await LojaService.updateEmissor(
                  emissorForm,
                  emissor.id
                );
                closeCreationModal();
                props.quandoFinalizar();
                notification.success({
                  message: 'Emissor editado com sucesso!',
                  top: 80,
                });
                fetchEmissor(props.cnpj ? props.cnpj : '0');
                setLoading(false);
              }
            } catch (error) {
              setLoading(false);
            }
          }}
        >
          <Row align={'top'} gutter={24}>
            <Col xs={24} lg={24}>
              <Form.Item
                label={'Chave de acesso'}
                name={'chaveDeAcesso'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input placeholder={'E.x.: xxxxxxxx'} />
              </Form.Item>
              <Form.Item
                label={'Chave de Homologação'}
                name={'chaveHomolog'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input placeholder={'E.x.: xxxxxxxx'} />
              </Form.Item>
              <Form.Item label={'Status de Emissão'} name={'status_emissao'}>
                <Select placeholder={'Selecione o status de  emissão'}>
                  <Select.Option value={'0'}>{'Todos'}</Select.Option>
                  <Select.Option value={'1'}>{'Aberto'}</Select.Option>
                  <Select.Option value={'2'}>{'Emissão'}</Select.Option>
                  <Select.Option value={'3'}>{'Produção'}</Select.Option>
                  <Select.Option value={'4'}>{'Entrega'}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label='Status' name={'ativo'}>
                <Switch
                  checkedChildren={'Ativo'}
                  unCheckedChildren={'Inativo'}
                  defaultChecked
                  checked={checkedAtivoEmissor}
                  onChange={setCheckedAtivoEmissor}
                />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Row justify={'end'}>
                <Button type={'primary'} htmlType={'submit'} loading={loading}>
                  {'Salvar'}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalEmissor;
