import { Card, Space, Table, Tag } from 'antd';
import { Col, Form, Input, Row, Select, Button, notification, Switch } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useCallback, useEffect, useState } from 'react';
import useBreadcrumb from '../core/hooks/useBreadcrumb';
import { UserService } from '../sdk';
import { Usuarios } from '../sdk/@types/Usuarios';
import { SearchOutlined } from '@ant-design/icons';
import LojaService from '../sdk/services/Loja.service';
import App from './Modal';
import ModalTerminal from './ModalTerminal';
import { Lojas } from '../sdk/@types/Restaurantes';
import ModalT from './ModalT';
import Skeleton from 'antd/lib/skeleton';
import ModalUltimoRetorno from './ModalUltimoRetorno';

interface TabelaTerminaisPorEmissorProps {
  cnpj?: string;
  emissor: Lojas.Emissor;
}
export default function TabelaTerminaisPorEmissor(props: TabelaTerminaisPorEmissorProps) {
  useBreadcrumb('Home/Lojas/Editar');

  const [data, setData] = useState<Lojas.TerminalTratado[]>();
  const [ativo, setAtivo] = useState<boolean>(true);
  const [fetching, setFetching] = useState<boolean>(true);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const openCreationModal = useCallback(() => setShowCreationModal(true), []);
  const closeCreationModal = useCallback(() => setShowCreationModal(false), []);

  const [reload, setReload] = useState<boolean>(true);

  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (props.cnpj !== '') {
      setFetching(true);
      setData([]);
      let cnpjAux = props.cnpj?.replaceAll('.', '');
      cnpjAux = cnpjAux?.replaceAll('/', '');
      cnpjAux = cnpjAux?.replaceAll('-', '');
      if (cnpjAux) {
        LojaService.getTerminaisDoEmissor(cnpjAux)
          .then(tratarListaDeEmissores)
          .then((resp)=>{console.log(resp);setData(resp);})
          .catch((error) => {
            setError(new Error(error.message));
          });
      }
      setFetching(false);
    } else {
      setFetching(false);
    }
  }, [reload]);

  function tratarListaDeEmissores(dados: any): any {
    console.log(dados);
    return dados._embedded.terminal;
  }

  const getColumnSearchProps = (
    dataIndex: keyof Lojas.TerminalTratado,
    displayName?: string
  ): ColumnProps<Lojas.TerminalTratado> => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
      <Card>
        <Input
          style={{ marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          //placeholder={`Buscar ${displayName || dataIndex}`}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => confirm()}
        />
        <Space>
          <Button
            type={'primary'}
            size={'small'}
            style={{ width: 90 }}
            onClick={() => confirm()}
            icon={<SearchOutlined />}
          >
            Buscar
          </Button>

          <Button onClick={clearFilters} size={'small'} style={{ width: 90 }}>
            Limpar
          </Button>
        </Space>
      </Card>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#0099ff' : undefined }} />
    ),
    // @ts-ignore
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : '',
  });

  const columns: any = [
    {
      title: 'Serie',
      dataIndex: 'serie',
      //...getColumnSearchProps('serie', 'serie'),
      key: 'serie',
    },
    {
      title: 'Último numero emissão',
      dataIndex: 'ultimoNumeroEmissao',
      //...getColumnSearchProps('ultimoNumeroEmissao', 'ultimoNumeroEmissao'),
      key: 'ultimoNumeroEmissao',
    },
    {
      title: 'Ativo',
      dataIndex: 'ativo',
      key: 'ativo ',
      render(ativo: boolean) {
        return ativo ? <Tag color='blue'>Ativo</Tag> : <Tag color='red'>Inativo</Tag>;
      },
    },
    {
      title: 'Ação',
      dataIndex: 'id',
      fixed: 'right',
      key: 'acao',
      render(id: string) {
        return (
          <div style={{display:"flex",gap:"10px"}}>
            <ModalTerminal
              quandoFinalizar={() => {
                setReload(!reload);
              }}
              idTerminal={id}
              idEmissor={props.emissor.id}
            ></ModalTerminal>
            <ModalUltimoRetorno
              quandoFinalizar={() => {
                setReload(!reload);
              }}
              idTerminal={id}
              idEmissor={props.emissor.id}
            ></ModalUltimoRetorno>
          </div>
        );
      },
    },
  ];

  function trataSwitch() {
    setAtivo(!ativo);
  }

  return (
    <>
      <ModalT
        quandoFinalizar={() => {
          setReload(!reload);
          setFetching(true);
        }}
        idEmissor={props.emissor?.id as string}
      ></ModalT>

      <Form
        autoComplete={'off'}
        layout={'horizontal'}
        onFinish={async (terminal: any) => {}}
      >
        <Col xs={24}>
          <Row justify={'start'}>
            <Table
              loading={fetching}
              pagination={{ pageSize: 20 }}
              dataSource={data}
              columns={columns}
              scroll={{ x: '1190' }}
            />
          </Row>
        </Col>
      </Form>
    </>
  );
}
