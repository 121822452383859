import useAuth from '../../core/hooks/useAuth';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import FormularioUsuario from '../features/FormularioUsuario';
import { Col } from 'antd';

function CadastroDeUsuarioView() {
  usePageTitle('Cadastro de Usuario');
  useBreadcrumb('Home/Usuarios/Cadastro');
  const { user } = useAuth();

  return (
    <>
      {' '}
      <Col xs={0} lg={24}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: '-35px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          ATENÇÃO: Utilizar para criar USUÁRIOS de Consumidores, Admin e etc. NÃO CRIAR
          USUÁRIO DE LOJA AQUI. Para MANAGER crie USUÁRIO pela LOJA
        </div>
        <div style={{ width: '100%', height: '35px' }}></div>
      </Col>
      <FormularioUsuario onSuccess={() => {}}></FormularioUsuario>
    </>
  );
}

export default CadastroDeUsuarioView;
