import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Modal, Button, Row, Col, notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormularioTerminal from '../app/features/FormularioTerminal';
import useTerminalEmissor from '../core/hooks/useTerminalEmissor';

const ModalUltimoRetorno = (props: {
  idTerminal: string;
  idEmissor?: string;
  quandoFinalizar: () => any;
}) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Form de cadastro de terminais');
  const [showCreationModal, setShowCreationModal] = useState(false);
  const openCreationModal = useCallback(() => setShowCreationModal(true), []);
  const closeCreationModal = useCallback(() => setShowCreationModal(false), []);
  const parametroUrl = useParams<{ id: string }>();

  //fazer a busca
  const { terminalEmissor, fetchterminalEmissor } = useTerminalEmissor();
  useEffect(() => {
    // chamar consulta do usuario novamente
    fetchterminalEmissor(props.idTerminal);
  }, [fetchterminalEmissor]);

  return (
    <>
      <Row align={'middle'} gutter={24}>
        <Col xs={24} lg={12}>
          <Button size='small' icon={<EyeOutlined />} onClick={openCreationModal} />
        </Col>
      </Row>
      <Modal
        title='Terminais'
        footer={null}
        visible={showCreationModal}
        onCancel={closeCreationModal}
        destroyOnClose
      >
      <div>{terminalEmissor? terminalEmissor.ultimoRetorno: ''}</div>
      </Modal>
    </>
  );
};

export default ModalUltimoRetorno;
