import { Col, Form, Input, Row, Select, Button, notification, Switch } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { UserService } from '../../sdk';
import { Usuarios } from '../../sdk/@types/Usuarios';
import jwtDecode from 'jwt-decode';
import { Authentication } from '../../auth/Auth';
import { userInfo } from 'os';
import { useCallback, useEffect, useState } from 'react';
import LojaService from '../../sdk/services/Loja.service';
import { Lojas } from '../../sdk/@types/Restaurantes';
import MaskedInput from 'antd-mask-input';

export default function FormularioNovoTerminal(props: {
  onSuccess: () => any;
  terminalEmissor?: Lojas.TerminalTratado;
  idEmissor?: string;
}) {
  const access_token = localStorage.getItem('accessToken');
  const parametroUrl = useParams<{ id: string }>();
  const idLoja: number = parametroUrl.id ? parseInt(parametroUrl.id) : 0;
  const decodedToken: Authentication.AccessTokenDecodedBody = jwtDecode(
    access_token as string
  );
  const [loading, setLoading] = useState(false);

  const { onSuccess } = props;

  const history = useHistory();

  return (
    <Form
      autoComplete={'off'}
      layout={'horizontal'}
      onFinish={async (novoTerminal: Lojas.TerminalTratado) => {
        setLoading(true);
        try {
          const bodyNewTerminal = {
            serie: novoTerminal.serie,
            ativo: true,
            ultimoNumeroEmissao: '0',
          };
          await LojaService.novoTerminal(bodyNewTerminal, props.idEmissor as string);

          onSuccess();
          setLoading(false);
        } catch (error) {
          setLoading(true);
        }
      }}
      initialValues={props.terminalEmissor}
    >
      <Row align={'top'} gutter={24}>
        <Col xs={24} lg={24}>
          <Form.Item
            label={'Série'}
            name={'serie'}
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
            ]}
          >
            <Input placeholder={'E.g.: 000'} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Row justify={'end'}>
            <Button type={'primary'} htmlType={'submit'} loading={loading}>
              {'Salvar'}
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
