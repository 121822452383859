import { Usuarios } from '../../sdk/@types/Usuarios';

function transformarDados(dados: Usuarios.Input): Usuarios.InputUsuario {
  const data1 = {
    nome: dados.nome,
    email: dados.email,
    senha: dados.senha,
    ativo: dados.ativo,
    bloqueio: dados.bloqueio,
    fone: dados.fone,
    usuario: dados.usuario,
  };

  return data1 as Usuarios.InputUsuario;
}

export default transformarDados;
