// User.service.ts
import transformarDados from '../../app/funcoes/TransformarDados';
import AuthService from '../../auth/Authorization.service';
import { Usuarios } from '../@types/Usuarios';
import Service from '../Service';

class UserService extends Service {
  static getGrupoUsuario(userId: string) {
    return this.Http.get<Usuarios.ListaGrupos>(`/v1/usuarios/${userId}/grupos`).then(
      this.getData
    );
  }
  static getAllGrupos() {
    return this.Http.get<Usuarios.ListaGrupos>(`/v1/grupos`).then(this.getData);
  }

  static associarGrupo(idUsuario: string, idGrupo: string) {
    return this.Http.put<{}>(`/v1/usuarios/${idUsuario}/grupos/${idGrupo}`).then(
      this.getData
    );
  }
  static desassociarGrupo(idUsuario: string, idGrupo: string) {
    return this.Http.delete<{}>(`/v1/usuarios/${idUsuario}/grupos/${idGrupo}`).then(
      this.getData
    );
  }
  static getUsuarioDetalhado(userId: string) {
    return this.Http.get<Usuarios.UsuarioDetalhado>(`/v1/usuarios/${userId}`).then(
      this.getData
    );
  }

  static getTodosComRestauranteEGrupoEStatus(status: boolean, grupo: string) {
    let statusInt;
    if (status) {
      statusInt = 1;
    } else {
      statusInt = 0;
    }

    return this.HttpTechFood.get<Usuarios.UsuarioComRestaurante[]>(
      `/v1/usuarios/todosComRestauranteGrupo/${statusInt}/${grupo}`
    ).then(this.getData);
  }

  static getAllUsers() {
    return this.Http.get<Usuarios.ListaUsuarios>('/v1/usuarios').then(this.getData);
  }

  static getRestaurantesPorStatus(status: boolean) {
    return this.HttpTechFood.get<Usuarios.ListaRestaurantes>(
      `/v1/restaurantes/status/${status ? 'Ativo' : 'Inativo'}/desc`
    ).then(this.getData);
  }

  static getRestaurantesDoUsuario(userId: string) {
    return this.HttpTechFood.get<Usuarios.ListaDeRestaurantesDoUsuario>(
      `/v1/restaurantes/usuario/${userId}`
    ).then(this.getData);
  }

  static insertNewUser(userData: Usuarios.Input) {
    const data = transformarDados(userData);

    return this.Http.post<any>('/v1/usuarios', data).then(this.getData);
  }
  static inserirUsuarioComGrupo(userData: Usuarios.Input, grupoId: string) {
    const data = transformarDados(userData);

    return this.Http.post<any>(`v1/usuarios/comgrupo/${grupoId}`, data).then(
      this.getData
    );
  }

  static editUser(userData: Usuarios.Edit, usuarioId: string) {
    return this.Http.put<Usuarios.RetornoCadastro>(
      `/v1/usuarios/${usuarioId}`,
      userData
    ).then(this.getData);
  }

  static ativarUsuario(idUsuario: string) {
    return this.Http.put<Usuarios.RetornoCadastro>(
      `/v1/usuarios/${idUsuario}/ativo`
    ).then(this.getData);
  }

  static inativarUsuario(idUsuario: string) {
    return this.Http.delete<Usuarios.RetornoCadastro>(
      `/v1/usuarios/${idUsuario}/ativo`
    ).then(this.getData);
  }

  static excluirUsuario(idUsuario: string) {
    return this.Http.delete<Usuarios.RetornoCadastro>(
      `/v1/usuarios/${idUsuario}/deletar`
    ).then(this.getData);
  }

  static bloquearUsuario(idUsuario: string) {
    return this.Http.put<Usuarios.RetornoCadastro>(
      `/v1/usuarios/${idUsuario}/bloqueio`
    ).then(this.getData);
  }

  static desbloquearUsuario(idUsuario: string) {
    return this.Http.delete<Usuarios.RetornoCadastro>(
      `/v1/usuarios/${idUsuario}/bloqueio`
    ).then(this.getData);
  }

  static inativarUsuarios(usuariosIds: string[]) {
    const body = { data: usuariosIds };

    return this.Http.delete<Usuarios.RetornoCadastro>(
      `/v1/usuarios/ativacoes`,
      body
    ).then(this.getData);
  }

  static ativarUsuarios(usuariosIds: string[]) {
    return this.Http.put<Usuarios.RetornoCadastro>(
      `/v1/usuarios/ativacoes`,
      usuariosIds
    ).then(this.getData);
  }
}

export default UserService;
