import {
  DeleteFilled,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Modal, Button, Row, Col, notification } from 'antd';
import Tooltip from 'antd/es/tooltip';
import Skeleton from 'antd/lib/skeleton';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormularioUsuario from '../app/features/FormularioUsuario';
import useLojaUsuario from '../core/hooks/useUserLoja';
import { UserService, Usuarios } from '../sdk';

const ModalUsuario = (props: { idUsuario: string; quandoFinalizar: () => any }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Form de cadastro de usuários');
  const [showCreationModal, setShowCreationModal] = useState(false);
  const openCreationModal = useCallback(() => setShowCreationModal(true), []);
  const closeCreationModal = useCallback(() => setShowCreationModal(false), []);
  const parametroUrl = useParams<{ id: string }>();

  //fazer a busca
  const { usuarioLoja, fetchUsuarioLoja } = useLojaUsuario();

  useEffect(() => {
    // chamar consulta do usuario novamente
    fetchUsuarioLoja(props.idUsuario);
  }, [fetchUsuarioLoja, props.idUsuario]);

  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      <Tooltip title={'Editar Usuário'} placement={'left'}>
        <Button size='small' icon={<EditOutlined />} onClick={openCreationModal} />
      </Tooltip>
      <Tooltip title={'Excluir Usuário'} placement={'right'}>
        <Button
          size='small'
          icon={<DeleteFilled />}
          type={'default'}
          htmlType={'button'}
          onClick={async () => {
            const confirmed = window.confirm(
              'Tem certeza de que deseja excluir o usuário?'
            );

            if (confirmed) {
              try {
                if (usuarioLoja?.id) {
                  await UserService.excluirUsuario(usuarioLoja?.id);
                  alert('Exclusão concluída com sucesso!');
                  props.quandoFinalizar();
                } else {
                  alert('Erro ao excluir o usuário.');
                }
              } catch (error) {
                alert('Erro ao excluir o usuário.');
              }
            }
          }}
          style={{ color: 'red' }}
        />
      </Tooltip>

      <Modal
        title='Usuários'
        footer={null}
        visible={showCreationModal}
        onCancel={closeCreationModal}
        destroyOnClose
      >
        {usuarioLoja ? (
          <FormularioUsuario
            onSuccess={() => {
              closeCreationModal();
              props.quandoFinalizar();
              notification.success({
                message: 'Usuário Editado com sucesso',
                top: 80,
              });
              fetchUsuarioLoja(props.idUsuario);
            }}
            usuarioLoja={usuarioLoja}
            modal={true}
          ></FormularioUsuario>
        ) : (
          <Skeleton></Skeleton>
        )}
      </Modal>
    </div>
  );
};

export default ModalUsuario;
