import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserService } from '../../sdk';
import { Lojas } from '../../sdk/@types/Restaurantes';
import LojaService from '../../sdk/services/Loja.service';

export default function useLoja() {
  const access_token = localStorage.getItem('accessToken');
  const params = useParams<{ id: string }>();
  const [loja, setLoja] = useState<Lojas.formRestaurante>();

  const fetchLoja = useCallback((lojaId: string) => {
  
    LojaService.getRestauranteById(lojaId).then(tratarDadosRestaurante).then((resp)=>{setLoja(resp);
      console.log(resp);
    });
  }, []);

  function tratarDadosRestaurante(dados: Lojas.Restaurante): Lojas.formRestaurante {
    console.log(dados);
    let dadosLoja: Lojas.formRestaurante = {
      nome: dados.nome,
      dominio: dados.dominio,
      bairro: dados.endereco.bairro,
      cep: dados.endereco.cep,
      endereco: dados.endereco.logradouro + ', ' + dados.endereco.numero,
      taxaFrete: dados.taxaFrete,
      cnpj: dados.cnpj,
      ie: dados.ie,
      basic_token: dados.basic_token,
      cozinha: dados.cozinha.id,
      cidade: dados.endereco.cidade.nome,
      bloqueio: dados.bloqueio,
      aberto: dados.aberto,
      ativo: dados.ativo,
      flag_Techsapp_Sincronizado: dados.flag_Techsapp_Sincronizado,
      crt: dados.crt,
      tp_amb: dados.tp_amb,
      estado: dados.endereco.cidade.estado,
      linhasTechsbot: dados.linhasTechsbot
    };

    return dadosLoja;
  }

  return {
    loja,
    fetchLoja,
  };
}
