import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import { Modal, Button, Row, Col, notification } from 'antd';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormularioUsuario from '../app/features/FormularioUsuario';

const App = (props: { quandoFinalizar: () => any }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Form de cadastro de usuários');
  const [showCreationModal, setShowCreationModal] = useState(false);
  const openCreationModal = useCallback(() => setShowCreationModal(true), []);
  const closeCreationModal = useCallback(() => setShowCreationModal(false), []);
  const params = useParams<{ id: string }>();

  return (
    <>
      <Row align={'middle'} gutter={24}>
        <Col xs={24} lg={12}>
          {!params.id ? (
            <Button
              type='primary'
              disabled
              onClick={openCreationModal}
              icon={<UserAddOutlined />}
            >
              Usuário
            </Button>
          ) : (
            <Button type='primary' onClick={openCreationModal} icon={<UserAddOutlined />}>
              Usuário
            </Button>
          )}
        </Col>
      </Row>
      <Modal
        title='Usuários'
        footer={null}
        visible={showCreationModal}
        onCancel={closeCreationModal}
        destroyOnClose
      >
        <FormularioUsuario
          onSuccess={() => {
            closeCreationModal();
            props.quandoFinalizar();
            notification.success({
              message: 'Usuário cadastrado com sucesso',
              top: 80,
            });
          }}
        ></FormularioUsuario>
      </Modal>
    </>
  );
};

export default App;
