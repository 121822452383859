import { Col, Form, Input, Row, Select, Button, notification, Switch, Space } from 'antd';
import App from '../../componentes/Modal';
import TabelaLojas from '../../componentes/TabelaLojas';
import TabelaEmissor from '../../componentes/TabelaEmissor';
import TabelaUsuariosPorLoja from '../../componentes/TabelaUsuariosPorLoja';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { userInfo } from 'os';
import { useEffect, useState } from 'react';
import { Authentication } from '../../auth/Auth';
import { Lojas } from '../../sdk/@types/Restaurantes';
import LojaService from '../../sdk/services/Loja.service';
import { Cidades } from '../../sdk/@types/Cidades';
import CidadeService from '../../sdk/services/Cidade.service';
import CozinhaService from '../../sdk/services/Cozinha.service';
import { Cozinhas } from '../../sdk/@types/Cozinha';
import { UserService, Usuarios } from '../../sdk';
import TabelaTerminaisPorEmissor from '../../componentes/TabelaTerminaisPorEmissor';

interface FormularioDeRamoProps {
  onSuccess: () => any;
  ramo?: Cozinhas.GradeRamos;
}

export default function FormularioDeRamo(props: FormularioDeRamoProps) {
  usePageTitle('Lista de Ramos');
  const access_token = localStorage.getItem('accessToken');
  const [reload, setReload] = useState<boolean>(true);
  const [recarregarTabela, setRecarregarTabela] = useState<boolean>(true);
  const [recarregarTabelaEmissor, setRecarregarTabelaEmissor] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [recarregartabelaTerminais, setRecarregartabelaTerminais] =
    useState<boolean>(true);
  const params = useParams<{ id: string }>();
  const decodedToken: Authentication.AccessTokenDecodedBody = jwtDecode(
    access_token as string
  );

  const { onSuccess } = props;

  const [error, setError] = useState<Error>();

  function tratarDados(dados: Cidades.Cidade): Cidades.Cidade {
    return dados;
  }

  function tratarDadosCozinha(dados: Cozinhas.Cozinha): Cozinhas.Cozinha {
    return dados;
  }

  const history = useHistory();

  return (
    <>
      <Form
        autoComplete={'off'}
        layout={'horizontal'}
        onFinish={async (ramo: Lojas.formLojas) => {
          // buscar lista de usuarios

          if (params.id) {
            const id = params.id;
          }
          if (params.id) {
            setLoading(true);
            let ramoAux = await CozinhaService.atualizarRamo(ramo, params.id);
            setLoading(false);
            if (ramoAux) {
              onSuccess();
              notification.success({
                message: 'Sucesso',
                description: 'Ramo atualizado com sucesso',
                top: 90,
              });
            }
          } else {
            setLoading(true);
            let ramoAux = await CozinhaService.cadastrarRamo(ramo);
            setLoading(false);
            onSuccess();
            notification.success({
              message: 'Sucesso',
              description: 'Ramo criado com sucesso',
            });
            history.push(`/ramo/editar/${ramoAux.id}`);
          }
        }}
        initialValues={props.ramo}
      >
        <Row align={'middle'}>
          <legend
            style={{
              borderTop: '1px solid #000',
              margin: '0',
              borderBottom: '0',
              borderLeft: '0',
              borderRight: '0',
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            {'Identificação'}
          </legend>
          <fieldset
            style={{
              width: ' 100%',
              display: 'flex',
              padding: '20px',
              gap: '10px',
            }}
          ></fieldset>
          <Col xs={24} lg={12}>
            <Form.Item
              label={'Nome'}
              name={'nome'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
                {
                  max: 255,
                  message: `O nome não pode ter mais de 255 caracteres`,
                },
              ]}
            >
              <Input
                style={{
                  width: '95%',
                }}
                placeholder={'E.x.: Thai Gourmeth'}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item>
              <Button
                type={'primary'}
                htmlType={'submit'}
                loading={loading}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              >
                {props.ramo ? 'Editar Ramo' : 'Cadastrar Ramo'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
