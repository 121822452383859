import { Skeleton } from 'antd';
import { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import useCidade from '../../core/hooks/useCidade';
import FormularioDeCidade from '../features/FormularioDeCidade';

export default function EdicaoDeCidadeView() {
  useBreadcrumb('Home/Ramo/EditarCidade');
  const parametroUrl = useParams<{ id: string }>();
  const { cidade, fetchCidade } = useCidade();

  useEffect(() => {
    fetchCidade(parametroUrl.id);
  }, [fetchCidade]);

  if (!cidade) return <Skeleton />;

  return (
    <>
      <FormularioDeCidade onSuccess={() => {}} cidade={cidade} />
    </>
  );
}
