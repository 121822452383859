import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import handleAxiosResponseSuccess from './utils/handleAxiosResponseSuccess';

const Http = axios.create();
const HttpTechNfe = axios.create();
const Http2 = axios.create();
const HttpTechFood = axios.create();
const AUTH_BASE_URL = process.env.REACT_APP_AUTH_SERVER_BASE_URL;
const NFCE_BASE_URL = process.env.REACT_APP_NFCE_URL;
const HTTPTECHFOOD_BASE_URL = process.env.REACT_APP_HTTPTECHFOOD_URL;

class Service {
  protected static Http = Http;
  protected static Http2 = Http2;
  protected static HttpTechNfe = HttpTechNfe;
  protected static HttpTechFood = HttpTechFood;
  protected static getData = getData;

  public static setRequestInterceptors(
    onFulfilled: (
      request: AxiosRequestConfig
    ) => AxiosRequestConfig | Promise<AxiosRequestConfig>,
    onRejected?: (error: any) => any
  ) {
    Http.interceptors.request.use(onFulfilled, onRejected);
    HttpTechNfe.interceptors.request.use(onFulfilled, onRejected);
    HttpTechFood.interceptors.request.use(onFulfilled, onRejected);
  }

  public static setResponseInterceptors(
    onFulfilled: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
    onRejected: (error: any) => any
  ) {
    Http.interceptors.response.use(onFulfilled, onRejected);
    HttpTechNfe.interceptors.response.use(onFulfilled, onRejected);
    HttpTechFood.interceptors.response.use(onFulfilled, onRejected);
  }
}

function getData<T>(res: AxiosResponse<T>) {
  return res.data;
}

Http.defaults.baseURL = AUTH_BASE_URL;
HttpTechNfe.defaults.baseURL = NFCE_BASE_URL;
Http2.defaults.baseURL = '';
HttpTechFood.defaults.baseURL = HTTPTECHFOOD_BASE_URL;

export default Service;
