import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import FormularioDeLoja from '../features/FormularioDeLoja';
import FormularioDeRamo from '../features/FormularioDeRamo';

export default function CadastroDeRamosView() {
  useBreadcrumb('Home/Ramos/Cadastro');
  return (
    <>
      <FormularioDeRamo onSuccess={() => {}} />
    </>
  );
}
