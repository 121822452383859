import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserService } from '../../sdk';
import { Cidades } from '../../sdk/@types/Cidades';
import { Cozinhas } from '../../sdk/@types/Cozinha';
import { Estados } from '../../sdk/@types/Estados';
import { Lojas } from '../../sdk/@types/Restaurantes';
import CidadeService from '../../sdk/services/Cidade.service';
import CozinhaService from '../../sdk/services/Cozinha.service';
import EstadoService from '../../sdk/services/Estado.service';
import LojaService from '../../sdk/services/Loja.service';

export default function useCidade() {
  const [cidade, setCidade] = useState<Cidades.GradeCidades>();

  const fetchCidade = useCallback((cidadeId: string) => {
    CidadeService.getById(cidadeId).then(tratarDadosCidade).then(setCidade);
  }, []);

  function tratarDadosCidade(dados: any): any {
    const formCidade = {
      id: dados.id,
      nome: dados.nome,
      ibge: dados.ibge,
      estado: dados.estado.id,
    };

    return formCidade;
  }

  return {
    cidade,
    fetchCidade,
  };
}
