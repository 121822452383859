import { Card, Space, Table, Tag } from 'antd';
import { Col, Form, Input, Row, Select, Button, notification, Switch } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import useBreadcrumb from '../core/hooks/useBreadcrumb';
import { UserService } from '../sdk';
import { Usuarios } from '../sdk/@types/Usuarios';
import {
  EyeOutlined,
  EditOutlined,
  SearchOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import ModalUsuario from './ModalUsuario';

export default function TabelaUsuarios() {
  const [data, setData] = useState<Usuarios.UsuarioComRestaurante[]>();
  const [ativo, setAtivo] = useState<boolean>(true);
  const [fetching, setFetching] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);
  const [grupos, setGrupos] = useState<any>([]);
  const [grupoEscolhido, setGrupoEscolhido] = useState<any>('5');
  const [error, setError] = useState<Error>();
  useEffect(() => {
    UserService.getAllGrupos().then(setGrupos);
  }, []);

  useEffect(() => {
    setFetching(true);
    UserService.getTodosComRestauranteEGrupoEStatus(ativo, grupoEscolhido)
      .then(tratarDados)
      .then(setData)
      .catch((error) => {
        setError(new Error(error.message));
      });
  }, [ativo, reload, grupoEscolhido]);

  function tratarDados(
    dados: Usuarios.UsuarioComRestaurante[]
  ): Usuarios.UsuarioComRestaurante[] {
    setFetching(false);
    return dados;
  }

  const getColumnSearchProps = (
    dataIndex: keyof Usuarios.UsuarioComRestaurante,
    displayName?: string
  ): ColumnProps<Usuarios.UsuarioComRestaurante> => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
      <Card>
        <Input
          style={{ marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          //placeholder={`Buscar ${displayName || dataIndex}`}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => confirm()}
        />
        <Space>
          <Button
            type={'primary'}
            size={'small'}
            style={{ width: 90 }}
            onClick={() => confirm()}
            icon={<SearchOutlined />}
          >
            Buscar
          </Button>

          <Button onClick={clearFilters} size={'small'} style={{ width: 90 }}>
            Limpar
          </Button>
        </Space>
      </Card>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#0099ff' : undefined }} />
    ),
    // @ts-ignore
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : '',
  });

  const columns: any = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      ...getColumnSearchProps('nome', 'Nome'),
      key: 'nome',
      width: 120,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ...getColumnSearchProps('email', 'Email'),
      key: 'email',
      width: 120,
    },
    {
      title: 'Restaurante',
      dataIndex: 'nomeRestaurante',
      ...getColumnSearchProps('nomeRestaurante', 'Restaurante'),
      key: 'nomeRestaurante',
      width: 100,
    },
    {
      title: 'GRUPO',
      dataIndex: 'grupo',
      key: 'grupo',
      width: 60,
      render(grupo: string) {
        return grupo === 'Bloqueado' ? (
          <Tag color='red'>{grupo}</Tag>
        ) : (
          <Tag color='blue'>{grupo}</Tag>
        );
      },
    },
    {
      title: 'Bloqueado',
      dataIndex: 'bloqueado',
      width: 60,
      key: 'bloqueado ',
      render(bloqueio: string) {
        return bloqueio === 'Bloqueado' ? (
          <Tag color='red'>{bloqueio}</Tag>
        ) : (
          <Tag color='blue'>{bloqueio}</Tag>
        );
      },
    },
    {
      title: 'Ativo',
      dataIndex: 'ativo',
      width: 60,
      key: 'ativo ',
      render(ativo: string) {
        return ativo === 'Ativo' ? (
          <Tag color='blue'>{ativo}</Tag>
        ) : (
          <Tag color='red'>{ativo}</Tag>
        );
      },
    },
    {
      title: 'Ação',
      dataIndex: 'id',
      width: 100,
      key: 'acao',
      fixed: 'right',

      render(id: string) {
        return (
          <>
            <ModalUsuario
              quandoFinalizar={() => {
                setReload(!reload);
              }}
              idUsuario={id}
            ></ModalUsuario>
          </>
        );
      },
    },
  ];

  function trataSwitch() {
    setAtivo(!ativo);
  }
  const onChangeGrupo = (value: string) => {
    console.log(`selected ${value}`);
    setGrupoEscolhido(value);
  };

  return (
    <>
      <Col xs={24} lg={8}>
        <Form.Item label={'Grupo'} name={'grupo'}>
          <Select
            placeholder={'Selecione o grupo'}
            defaultValue={'Admin'}
            onChange={onChangeGrupo}
          >
            {grupos._embedded
              ? grupos._embedded.grupos.map((grupo: any) => (
                  <>
                    <Select.Option value={grupo.id}> {grupo.nome}</Select.Option>
                  </>
                ))
              : ''}
          </Select>
        </Form.Item>
      </Col>
      <Form autoComplete={'off'} layout={'horizontal'} onFinish={async (user: any) => {}}>
        <Col xs={24}>
          <Row justify={'start'}>
            <Form.Item label='Status' valuePropName='checked'>
              <Switch
                onChange={trataSwitch}
                defaultChecked
                checkedChildren={'Ativos'}
                unCheckedChildren={'Inativos'}
                loading={fetching}
              />
            </Form.Item>
          </Row>
        </Col>
        <Table
          loading={fetching}
          pagination={{ pageSize: 10 }}
          dataSource={data}
          columns={columns}
          scroll={{ x: '1190' }}
        />
      </Form>{' '}
    </>
  );
}
