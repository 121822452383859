import { Card, Space, Table, Tag, Tooltip } from 'antd';
import { Col, Form, Input, Row, Select, Button, notification, Switch } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import useBreadcrumb from '../core/hooks/useBreadcrumb';
import { UserService } from '../sdk';
import { Usuarios } from '../sdk/@types/Usuarios';
import {
  EyeOutlined,
  EditOutlined,
  SearchOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CidadeService from '../sdk/services/Cidade.service';
import { Cidades } from '../sdk/@types/Cidades';

export default function TabelaCidades() {
  const [data, setData] = useState<any[]>();
  const [fetching, setFetching] = useState<boolean>(true);

  const [error, setError] = useState<Error>();

  useEffect(() => {
    setFetching(true);
    CidadeService.getAll()
      .then(tratarDados)
      .then(setData)
      .catch((error) => {
        setError(new Error(error.message));
      });
  }, []);

  function tratarDados(dados: Cidades.Cidade): any {
    if (dados._embedded === undefined) {
      setFetching(false);
      return [];
    } else {
      setFetching(false);
      const cidadeAux: {
        id: string;
        ibge: string;
        nome: string;
        estado: string;
      }[] = Array();

      dados._embedded.cidades.map((cidadeAtual) => {
        const cidadeFormatada = {
          id: cidadeAtual.id,
          ibge: cidadeAtual.ibge,
          nome: cidadeAtual.nome,
          estado: cidadeAtual.estado.nome,
        };

        cidadeAux.push(cidadeFormatada);
      });

      return cidadeAux;
    }
  }

  const getColumnSearchProps = (
    dataIndex: keyof Cidades.GradeCidades,
    displayName?: string
  ): ColumnProps<Cidades.GradeCidades> => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
      <Card>
        <Input
          style={{ marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          //placeholder={`Buscar ${displayName || dataIndex}`}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => confirm()}
        />
        <Space>
          <Button
            type={'primary'}
            size={'small'}
            style={{ width: 90 }}
            onClick={() => confirm()}
            icon={<SearchOutlined />}
          >
            Buscar
          </Button>

          <Button onClick={clearFilters} size={'small'} style={{ width: 90 }}>
            Limpar
          </Button>
        </Space>
      </Card>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#0099ff' : undefined }} />
    ),
    // @ts-ignore
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : '',
  });

  const columns: any = [
    {
      title: 'Cidade',
      dataIndex: 'nome',
      ...getColumnSearchProps('nome', 'Nome'),
      key: 'nome',
    },
    {
      title: 'IBGE',
      dataIndex: 'ibge',
      ...getColumnSearchProps('ibge', 'IBGE'),
      key: 'ibge',
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      ...getColumnSearchProps('estado', 'Estado'),
      key: 'estado',
    },
    {
      title: 'Ação',
      dataIndex: 'id',
      fixed: 'right',
      key: 'acao',
      render(id: number) {
        return (
          <>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Button size='small' icon={<EyeOutlined />} />
              <Tooltip title={'Editar Cidade'} placement={'right'}>
                <Link to={`/cidade/editar/${id}`}>
                  <Button size='small' icon={<EditOutlined />}></Button>
                </Link>
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <Form autoComplete={'off'} layout={'horizontal'} onFinish={async (user: any) => {}}>
      <Table
        loading={fetching}
        pagination={{ pageSize: 20 }}
        dataSource={data}
        columns={columns}
        scroll={{ x: '1190' }}
      />
    </Form>
  );
}
