import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Lojas } from '../../sdk/@types/Restaurantes';
import LojaService from '../../sdk/services/Loja.service';

export default function useTerminalEmissor() {
  const access_token = localStorage.getItem('accessToken');
  const params = useParams<{ id: string }>();
  const [terminalEmissor, setTerminalEmissor] = useState<Lojas.TerminalTratado>();

  const fetchterminalEmissor = useCallback((terminalId: string) => {
    LojaService.getTerminalById(terminalId)
      .then(trataDadosTerminal)
      .then(setTerminalEmissor);
  }, []);

  function trataDadosTerminal(dados: Lojas.TerminalTratado): Lojas.TerminalTratado {
    return dados;
  }

  return {
    terminalEmissor,
    fetchterminalEmissor,
  };
}
