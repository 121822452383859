import { Col, Form, Input, Row, Select, Button, notification, Switch } from 'antd';
import TabelaUsuarios from '../../componentes/TabelaUsuarios';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';

function ConsultaUsuariosView() {
  usePageTitle('Lista de Usuarios');
  useBreadcrumb('Home/Usuarios/Consulta');

  return (
    <>
      <Row>
        <Col xs={0} lg={24}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: '-35px',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          >
            ATENÇÃO: Utilizar para criar USUÁRIOS de Consumidores, Admin e etc. NÃO CRIAR
            USUÁRIO DE LOJA AQUI. Para MANAGER crie USUÁRIO pela LOJA
          </div>
          <div style={{ width: '100%', height: '15px' }}></div>
        </Col>
        <Col xs={24} lg={0}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',

              fontSize: '13px',
            }}
          >
            ATENÇÃO: Utilizar para criar USUÁRIOS de Consumidores, Admin e etc. NÃO CRIAR
            USUÁRIO DE LOJA AQUI. Para MANAGER crie USUÁRIO pela LOJA
          </div>
        </Col>
      </Row>
      <TabelaUsuarios></TabelaUsuarios>
    </>
  );
}

export default ConsultaUsuariosView;
