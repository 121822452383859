import { Card, Dropdown, Layout, Menu, Row, Tag } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import Avatar from 'antd/lib/avatar/avatar';
import Meta from 'antd/lib/card/Meta';
import logo from '../../../assets/logotech.png';
import useAuth from '../../../core/hooks/useAuth';
import { Link } from 'react-router-dom';
import confirm from 'antd/lib/modal/confirm';
import AuthService from '../../../auth/Authorization.service';
import ModalUsuario from '../../../componentes/ModalUsuario';

const { Header } = Layout;

export default function DefaultLayoutHeader() {
  const { user } = useAuth();

  return (
    <Header className='header no-print'>
      <Row
        justify='space-between'
        style={{
          height: '100%',
          maxWidth: 1190,
          margin: '0 auto',
        }}
        align='middle'
      >
        <img src={logo} alt={'AlgaNews Admin'} />
        <Dropdown
          placement={'bottomRight'}
          overlay={
            <Menu style={{ width: 220 }}>
              <Card bordered={false}>
                <Meta
                  title={user?.usuario.nome}
                  description={user?.grupos?._embedded.grupos.map((grup) => (
                    <Tag color={grup.nome === 'Gerente' ? 'red' : 'blue'}>
                      {user?.grupos?._embedded.grupos.length === undefined
                        ? 'Indefinido'
                        : user?.grupos?._embedded.grupos.length >= 1
                        ? grup.nome + '\n'
                        : 'Indefinido'}
                    </Tag>
                  ))}
                />
              </Card>
              <Menu.Item icon={<UserOutlined />}>
                <Link to={`/usuarios/editar/${user?.usuario.id}`}>Meu perfil</Link>
              </Menu.Item>

              <Menu.Item
                icon={<LogoutOutlined />}
                onClick={() =>
                  confirm({
                    title: 'Fazer logout',
                    content:
                      'Deseja realmente fazer o logout? Será necessário inserir as credenciais novamente.',
                    onOk() {
                      AuthService.imperativelySendToLogout();
                    },
                    closable: true,
                    okButtonProps: { danger: true },
                    okText: 'Fazer logout',
                    cancelText: 'Permanecer logado',
                  })
                }
                danger
              >
                Fazer logout
              </Menu.Item>
            </Menu>
          }
        >
          <Avatar icon={<UserOutlined />} />
        </Dropdown>
      </Row>
    </Header>
  );
}
