import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import { Modal, Button, Row, Col, notification, Input, Form, Switch } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormularioEmissor from '../app/features/FormularioEmissor';
import useEmissor from '../core/hooks/useEmissor';
import { Lojas } from '../sdk/@types/Restaurantes';
import LojaService from '../sdk/services/Loja.service';
import ModalEmissor from './ModalEmissor';

const ModalCadastroEmissor = (props: {
  quandoFinalizar: () => any;
  aoCadastrar: () => any;
  emissor?: Lojas.EmissorResumido[];
  cnpj: string;
}) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [ModalCadastroEmisso, setModalCadastroEmissor] = useState(
    'Form de cadastro de usuários'
  );
  const [showCreationModal, setShowCreationModal] = useState(false);
  const openCreationModal = useCallback(() => setShowCreationModal(true), []);
  const closeCreationModal = useCallback(() => setShowCreationModal(false), []);
  const [checkedAtivoEmissor, setCheckedAtivoEmissor] = useState(true);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Row align={'middle'}>
        <Col xs={24} lg={12} style={{ marginTop: '10px' }}>
          {props.emissor ? (
            <Button
              type='primary'
              disabled
              onClick={openCreationModal}
              icon={<UserAddOutlined />}
            >
              Emissor
            </Button>
          ) : (
            <Button type='primary' onClick={openCreationModal} icon={<UserAddOutlined />}>
              Emissor
            </Button>
          )}
        </Col>
      </Row>
      <Modal
        title='Emissor'
        footer={null}
        visible={showCreationModal}
        onCancel={closeCreationModal}
        destroyOnClose
      >
        <Form
          autoComplete={'off'}
          layout={'horizontal'}
          onFinish={async (emissorForm: Lojas.NovoEmissor) => {
            setLoading(true);
            try {
              emissorForm.ativo = checkedAtivoEmissor;
              let cnpjAux = props.cnpj.replaceAll('.', '');
              cnpjAux = cnpjAux.replaceAll('/', '');
              cnpjAux = cnpjAux.replaceAll('-', '');
              emissorForm.cnpj = cnpjAux;

              let emissorAux: any = await LojaService.novoEmissor(emissorForm);
              closeCreationModal();
              props.quandoFinalizar();
              props.aoCadastrar();
              notification.success({
                message: 'Emissor Salvo com sucesso',
                top: 80,
              });
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
          }}
        >
          <Row align={'top'} gutter={24}>
            <Col xs={24} lg={24}>
              <Form.Item
                label={'Chave de acesso'}
                name={'chaveDeAcesso'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input placeholder={'E.x.: xxxxxxxx'} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label='status' name={'ativo'}>
                <Switch
                  checkedChildren={'Ativo'}
                  unCheckedChildren={'Inativo'}
                  defaultChecked
                  checked={checkedAtivoEmissor}
                  onChange={setCheckedAtivoEmissor}
                />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Row justify={'end'}>
                <Button type={'primary'} htmlType={'submit'} loading={loading}>
                  {'Salvar'}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCadastroEmissor;
