// User.service.ts
import transformarDados from '../../app/funcoes/TransformarDados';
import { Lojas } from '../@types/Restaurantes';
import { Usuarios } from '../@types/Usuarios';
import Service from '../Service';

class LojaService extends Service {
  static getGrupoUsuario(userId: string) {
    return this.Http.get<Usuarios.ListaGrupos>(`/v1/usuarios/${userId}/grupos`).then(
      this.getData
    );
  }

  static getUsuarioDetalhado(userId: string) {
    return this.Http.get<Usuarios.UsuarioDetalhado>(`/v1/usuarios/${userId}`).then(
      this.getData
    );
  }

  static getTodosComRestaurante(status: boolean) {
    return this.HttpTechFood.get<Usuarios.UsuarioComRestaurante[]>(
      `/v1/usuarios/todosComRestaurante/${status}`
    ).then(this.getData);
  }

  static getAllUsers() {
    return this.Http.get<Usuarios.ListaUsuarios>('/v1/usuarios').then(this.getData);
  }

  static getRestaurantesPorStatus(status: boolean) {
    return this.HttpTechFood.get<Usuarios.ListaRestaurantes>(
      `/v1/restaurantes/status/${status}`
    ).then(this.getData);
  }

  static getRestaurantesDoUsuario(userId: string) {
    return this.HttpTechFood.get<Usuarios.ListaDeRestaurantesDoUsuario>(
      `/v1/restaurantes/usuario/${userId}`
    ).then(this.getData);
  }

  static getRestauranteById(restauranteId: string) {
    return this.HttpTechFood.get<Lojas.Restaurante>(
      `/v1/restaurantes/${restauranteId}`
    ).then(this.getData);
  }

  static cadastrarLoja(data: Lojas.RestauranteInput) {
    return this.HttpTechFood.post<Lojas.RestauranteModel>('/v1/restaurantes', data).then(
      this.getData
    );
  }
  static cadastrarFormaDePagamento(restauranteId: string, idFormaPagamento:string) {
    return this.HttpTechFood.put(
      `/v1/restaurantes/${restauranteId}/formas-pagamento/${idFormaPagamento}`,
      {}
    ).then(this.getData);
  }

  static editarLoja(data: Lojas.RestauranteInput, restauranteId: string) {
    return this.HttpTechFood.put<Lojas.RestauranteModel>(
      `/v1/restaurantes/${restauranteId}`,
      data
    ).then(this.getData);
  }

  static importarProdutosTechsapp(idLoja: string, dominio: string, tokenLoja: string) {
    return this.HttpTechFood.get<Usuarios.ListaUsuarios>(
      `/v1/restaurantes/${idLoja}/produtos/sync/${dominio}/${tokenLoja}`
    ).then(this.getData);
  }
  static getResponsaveisDaLoja(idLoja: string) {
    return this.HttpTechFood.get<Usuarios.ListaUsuarios>(
      `/v1/restaurantes/${idLoja}/responsaveis`
    ).then(this.getData);
  }

  static getTerminaisDoEmissor(emissor: string) {
    return this.HttpTechNfe.get<Lojas.ListaTerminais>(
      `/v1/terminal/terminaisEmissor/${emissor}`
    ).then(this.getData);
  }

  static getTerminalById(id: string) {
    return this.HttpTechNfe.get<Lojas.TerminalTratado>(`/v1/terminal/${id}`).then(
      this.getData
    );
  }

  static getEmissorByCnpj(cnpj: string) {
    return this.HttpTechNfe.get<Lojas.Emissor>(`/v1/emissor/cnpj/${cnpj}`).then(
      this.getData
    );
  }

  static updateTerminal(data: Lojas.TerminalTratado, emissorId: string) {
    const bodyUpdateTerminal = {
      serie: data.serie,
      ativo: data.ativo,
      ultimoNumeroEmissao: data.ultimoNumeroEmissao,
      ultimoRetorno:data.ultimoRetorno
    };
    return this.HttpTechNfe.put<any>(
      `/v1/terminal/${emissorId}/${data.id}`,
      bodyUpdateTerminal
    );
  }

  static novoTerminal(data: Lojas.NovoTerminal, emissorId: string) {
    return this.HttpTechNfe.post<Lojas.RestauranteModel>(
      `/v1/terminal/${emissorId}`,
      data
    ).then(this.getData);
  }

  static updateEmissor(data: any, emissorId: string) {
    return this.HttpTechNfe.put<Lojas.RestauranteModel>(
      `/v1/emissor/${emissorId}`,
      data
    ).then(this.getData);
  }

  static novoEmissor(data: any) {
    return this.HttpTechNfe.post<Lojas.RestauranteModel>(`/v1/emissor`, data).then(
      this.getData
    );
  }

  static associarLoja(restauranteId: string, usuarioId: string) {
    return this.HttpTechFood.put<{}>(
      `/v1/restaurantes/${restauranteId}/responsaveis/${usuarioId}`
    ).then(this.getData);
  }

  static importarLoja(tokenTechsapp: string, plataforma: string) {
    return this.Http2.get<{}>(
      `https://www.${plataforma}.com.br/backend/api/v2/produto/importarLoja`,
      {
        headers: { Authorization: `Basic ${tokenTechsapp}` },
      }
    ).then(this.getData);
  }
}

export default LojaService;
