import { Col, Form, Input, Row, Select, Button, notification, Switch, Space } from 'antd';
import App from '../../componentes/Modal';
import TabelaLojas from '../../componentes/TabelaLojas';
import TabelaEmissor from '../../componentes/TabelaEmissor';
import TabelaUsuariosPorLoja from '../../componentes/TabelaUsuariosPorLoja';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { userInfo } from 'os';
import { useEffect, useState } from 'react';
import { Authentication } from '../../auth/Auth';
import { Lojas } from '../../sdk/@types/Restaurantes';
import LojaService from '../../sdk/services/Loja.service';
import { Cidades } from '../../sdk/@types/Cidades';
import CidadeService from '../../sdk/services/Cidade.service';
import CozinhaService from '../../sdk/services/Cozinha.service';
import { Cozinhas } from '../../sdk/@types/Cozinha';
import { UserService, Usuarios } from '../../sdk';
import TabelaTerminaisPorEmissor from '../../componentes/TabelaTerminaisPorEmissor';
import { Estados } from '../../sdk/@types/Estados';
import EstadoService from '../../sdk/services/Estado.service';

interface FormularioDeCidadeProps {
  onSuccess: () => any;
  cidade?: Cidades.GradeCidades;
}

export default function FormularioDeEstado(props: FormularioDeCidadeProps) {
  usePageTitle('Cadastro de Cidades');
  const access_token = localStorage.getItem('accessToken');
  const [loading, setLoading] = useState<boolean>(false);
  const [estados, setEstados] = useState<Estados.Estado>();

  const params = useParams<{ id: string }>();
  const decodedToken: Authentication.AccessTokenDecodedBody = jwtDecode(
    access_token as string
  );
  const { Option } = Select;

  const { onSuccess } = props;
  const onChange = (value: number) => {
    //console.log(`selected ${value}`);
  };

  const onSearch = (value: string) => {
    //console.log('search:', value);
  };
  const [error, setError] = useState<Error>();

  const history = useHistory();

  useEffect(() => {
    EstadoService.getAll()
      .then(setEstados)
      .catch((error) => {
        setError(new Error(error.message));
      });
  }, []);

  return (
    <>
      <Form
        autoComplete={'off'}
        layout={'horizontal'}
        onFinish={async (cidade: Cidades.formCidades) => {
          const cidadeInput: Cidades.formCidadesInput = {
            ibge: cidade.ibge,
            estado: { id: cidade.estado },
            nome: cidade.nome,
          };

          if (params.id) {
            setLoading(true);
            let estadoAux = await CidadeService.atualizarCidade(cidadeInput, params.id);
            setLoading(false);
            if (estadoAux) {
              onSuccess();
              notification.success({
                message: 'Sucesso',
                description: 'Cidade atualizada com sucesso',
                top: 90,
              });
            }
          } else {
            setLoading(true);
            let estadoAux = await CidadeService.cadastrarCidade(cidadeInput);
            setLoading(false);
            onSuccess();
            notification.success({
              message: 'Sucesso',
              description: 'Cidade criada com sucesso',
              top: 90,
            });
            history.push(`/cidade/editar/${estadoAux.id}`);
          }
        }}
        initialValues={props.cidade}
      >
        <Row align={'middle'}>
          <legend
            style={{
              borderTop: '1px solid #000',
              margin: '0',
              borderBottom: '0',
              borderLeft: '0',
              borderRight: '0',
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            {'Identificação'}
          </legend>
          <fieldset
            style={{
              width: ' 100%',
              display: 'flex',
              padding: '20px',
              gap: '10px',
            }}
          ></fieldset>
          <Col xs={24} lg={12}>
            <Form.Item
              label={'Cidade'}
              name={'nome'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
                {
                  max: 255,
                  message: `O nome não pode ter mais de 255 caracteres`,
                },
              ]}
            >
              <Input
                style={{
                  width: '95%',
                }}
                placeholder={'E.x.: São Paulo'}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label={'Estado'} name={'estado'}>
              <Select
                placeholder={'Selecione o Estado'}
                showSearch
                onChange={onChange}
                onSearch={onSearch}
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              >
                {estados?._embedded.estados.map((estado) => (
                  <Option value={estado.id}>{estado.nome}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={'IBGE'}
              name={'ibge'}
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório',
                },
                {
                  max: 255,
                  message: `O nome não pode ter mais de 255 caracteres`,
                },
              ]}
            >
              <Input
                style={{
                  width: '95%',
                }}
                type={'number'}
                placeholder={'E.x.: 35'}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Space size={8}>
              <Button
                type={'primary'}
                htmlType={'submit'}
                loading={loading}
                disabled={
                  !decodedToken.authorities.includes('2B701373CF50B2A00710398FAC1F6C84')
                }
              >
                Salvar Cidade
              </Button>

              <Button
                type={'primary'}
                htmlType={'button'}
                style={{
                  backgroundColor: props.cidade ? 'red' : '#E5E5E5',
                  borderColor: props.cidade ? 'red' : '#E5E5E5',
                }}
                disabled={props.cidade ? false : true}
                onClick={async () => {
                  if (props.cidade) {
                    const respExclusão = await CidadeService.excluirCidadeByID(
                      props.cidade.id as string
                    );
                    notification.success({
                      message: 'Sucesso',
                      description: 'Cidade excluida com sucesso',
                      top: 80,
                    });
                    history.push(`/cidade`);
                  }
                }}
              >
                Excluir Cidade
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
}
