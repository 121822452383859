import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import FormularioDeLoja from '../features/FormularioDeLoja';

export default function CadastroDeLojaView() {
  useBreadcrumb('Home/Lojas/Cadastro ');
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginTop: '-35px',
          fontWeight: 'bold',
        }}
      >
        ATENÇÃO: Utilizar Para Criar Usuário Proprietário da Loja (Que vai usar o Manager)
      </div>
      <div style={{ width: '100%', height: '15px' }}></div>
      <FormularioDeLoja />
    </>
  );
}
